import React from 'react';

const buttonStyles = {
  base: {
    padding: '8px 16px',
    margin: '0 8px',
    borderRadius: '4px',
    border: '2px solid',
    cursor: 'pointer',
    fontWeight: 'bold',
    transition: 'all 0.3s ease',
    minWidth: '120px',
  },
  mainActive: {
    backgroundColor: '#13ACB1',
    color: 'white',
    borderColor: '#13ACB1'
  },
  mainInactive: {
    backgroundColor: 'transparent',
    color: '#13ACB1',
    borderColor: '#13ACB1'
  },
  preDefinedActive: {
    backgroundColor: '#9585EC',
    color: 'white',
    borderColor: '#9585EC'
  },
  preDefinedInactive: {
    backgroundColor: 'transparent',
    color: '#9585EC',
    borderColor: '#9585EC'
  }
};

const SearchTypeButtons = ({ activeType, onChange }) => {
    const mainSearchTypes = [
        { value: 'standard', label: 'Standard' },
        { value: 'jobIdList', label: 'JobID List' },
        { value: 'referenceList', label: 'Reference List' },
        { value: 'pre-defined', label: 'Pre-defined' }
        ];

  const handleClick = (value) => {
    onChange({ target: { value } });
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <span style={{ color: 'white', fontWeight: 'bold', marginRight: '16px' }}>Search Type:</span>
        <div style={{ display: 'flex' }}>
          {mainSearchTypes.map((type) => (
            <button
              key={type.value}
              onClick={() => handleClick(type.value)}
              style={{
                ...buttonStyles.base,
                ...(activeType === type.value ? buttonStyles.mainActive : buttonStyles.mainInactive)
              }}
              onMouseEnter={(e) => {
                if (activeType !== type.value) {
                  e.target.style.backgroundColor = 'rgba(19, 172, 177, 0.1)';
                }
              }}
              onMouseLeave={(e) => {
                if (activeType !== type.value) {
                  e.target.style.backgroundColor = 'transparent';
                }
              }}
            >
              {type.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

const PreDefinedButtons = ({ options, onSelect, searchNameFromUrl }) => {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', padding: '16px' }}>
      {options.map((option) => {
        const buttonUrlName = option.buttonText.toLowerCase().replace(/\s+/g, '-');
        const isActive = buttonUrlName === searchNameFromUrl?.toLowerCase();
        
        return (
          <button
            key={option.searchID}
            onClick={() => onSelect(option)}
            style={{
              ...buttonStyles.base,
              ...(isActive ? buttonStyles.preDefinedActive : buttonStyles.preDefinedInactive)
            }}
            onMouseEnter={(e) => {
              if (!isActive) {
                e.target.style.backgroundColor = 'rgba(149, 133, 236, 0.1)';
              }
            }}
            onMouseLeave={(e) => {
              if (!isActive) {
                e.target.style.backgroundColor = 'transparent';
              }
            }}
          >
            {option.buttonText}
          </button>
        );
      })}
    </div>
  );
};

const SearchButtons = {
  SearchTypeButtons,
  PreDefinedButtons
};

export default SearchButtons;