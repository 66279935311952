import React, { useState, useRef, useEffect } from 'react';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactDOM from 'react-dom';

const SideMenu = ({
  navigation,
  onMenuItemClick,
  performTripFunction,
  storageKey = 'sideMenuState',
  disabledCondition,
  disabledMessage,
  onDrawerToggle
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(() => {
    const storedState = localStorage.getItem(`${storageKey}_drawerState`);
    return storedState ? JSON.parse(storedState) : true;
  });

  const [sectionVisibility, setSectionVisibility] = useState(() => {
    const storedVisibility = localStorage.getItem(`${storageKey}_sectionVisibility`);
    return storedVisibility ? JSON.parse(storedVisibility) : 
      Object.fromEntries(navigation.map(section => [section.id, true]));
  });

  const [hoveredSection, setHoveredSection] = useState(null);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });
  const hoverTimeoutRef = useRef(null);

  // Add state for tracking modifier keys
  const [modifiersPressed, setModifiersPressed] = useState({
    ctrl: false,
    shift: false
  });

  useEffect(() => {
    localStorage.setItem(`${storageKey}_drawerState`, JSON.stringify(isDrawerOpen));
    if (onDrawerToggle) {
      onDrawerToggle(isDrawerOpen);
    }
  }, [isDrawerOpen, storageKey, onDrawerToggle]);

  useEffect(() => {
    localStorage.setItem(`${storageKey}_sectionVisibility`, JSON.stringify(sectionVisibility));
  }, [sectionVisibility, storageKey]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Control' || e.key === 'Shift') {
        setModifiersPressed(prev => ({
          ...prev,
          ctrl: e.key === 'Control' ? true : prev.ctrl,
          shift: e.key === 'Shift' ? true : prev.shift
        }));
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === 'Control' || e.key === 'Shift') {
        setModifiersPressed(prev => ({
          ...prev,
          ctrl: e.key === 'Control' ? false : prev.ctrl,
          shift: e.key === 'Shift' ? false : prev.shift
        }));
      }
    };

    const handleBlur = () => setModifiersPressed({ ctrl: false, shift: false });

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  useEffect(() => {
    return () => {
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const handleHotkeyPress = (event) => {
      const matchingFunction = navigation.flatMap(section => section.options || []).find(func => {
        if (!func.hotKey) return false;
        
        const hotKeyParts = func.hotKey.toLowerCase().replace('alt', 'shift').split('-');
        
        const ctrlPressed = hotKeyParts.includes('ctrl') ? event.ctrlKey : true;
        const shiftPressed = hotKeyParts.includes('shift') ? event.shiftKey : true;
        
        const keyToMatch = hotKeyParts.find(part => 
          !['ctrl', 'shift'].includes(part)
        );
        
        const keyPressed = keyToMatch 
          ? event.key.toLowerCase() === keyToMatch.toLowerCase() 
          : true;
        
        return ctrlPressed && shiftPressed && keyPressed;
      });

      if (matchingFunction) {
        event.preventDefault();
        event.stopPropagation();
        
        const section = navigation.find(nav => 
          nav.options && nav.options.includes(matchingFunction)
        );

        if (performTripFunction) {
          performTripFunction(matchingFunction, section.id);
        }
      }
    };

    window.addEventListener('keydown', handleHotkeyPress);
    
    return () => {
      window.removeEventListener('keydown', handleHotkeyPress);
    };
  }, [navigation, performTripFunction]);

  const toggleDrawer = () => {
    setIsDrawerOpen(prev => !prev);
  };

  const getColorForSection = (sectionId, isPopover = false) => {
    const colors = {
      Dispatch: ['#715DDC', '#715DDC', '#715DDC'],
      Service: ['#DE3562', '#DE3562', '#DE3562'],
      Rating: ['#13ACB1', '#13ACB1', '#13ACB1'],
      Process: ['#715DDC', '#715DDC', '#715DDC']
    };
    const sectionColors = colors[sectionId] || colors.Process;
    return isPopover ? sectionColors[1] : `${sectionColors[0]}${isPopover ? '' : '66'}`;
  };

  const parseHotkey = (hotkeyStr) => {
    if (!hotkeyStr) return null;
    const parts = hotkeyStr.toLowerCase().split('-');
    return {
      ctrl: parts.includes('ctrl'),
      alt: parts.includes('alt'),
      key: parts.find(part => !['ctrl', 'alt'].includes(part))
    };
  };

  const shouldHighlight = (hotKey) => {
    if (!hotKey) return false;
    const parsed = parseHotkey(hotKey);
    return parsed && 
           modifiersPressed.ctrl === parsed.ctrl && 
           modifiersPressed.shift === parsed.alt;
  };

  const getHotkeyDisplay = (hotKey) => {
    if (!hotKey) return '';
    const parsed = parseHotkey(hotKey);
    return parsed.key.toUpperCase();
  };

  const toggleSectionVisibility = (sectionId) => {
    setSectionVisibility(prev => ({
      ...prev,
      [sectionId]: !prev[sectionId]
    }));
  };

  const handleSectionHover = (sectionId) => {
    if (!isDrawerOpen) {
      clearTimeout(hoverTimeoutRef.current);
      const element = document.getElementById(sectionId);
      if (element) {
        const rect = element.getBoundingClientRect();
        setPopoverPosition({
          top: rect.top,
          left: rect.right + 5,
        });
      }
      setHoveredSection(sectionId);
      setIsPopoverVisible(true);
    }
  };

  const handleSectionLeave = () => {
    if (!isDrawerOpen) {
      hoverTimeoutRef.current = setTimeout(() => {
        setIsPopoverVisible(false);
      }, 300);
    }
  };

  const handlePopoverEnter = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
    setIsPopoverVisible(true);
  };
  
  const handlePopoverLeave = () => {
    hoverTimeoutRef.current = setTimeout(() => {
      setIsPopoverVisible(false);
    }, 300);
  };

  const renderSectionContent = (sectionId) => {
    const menuOptions = navigation.find(nav => nav.id === sectionId)?.options || [];
  
    return (
      <div>
        {menuOptions.map((item, index) => {
          const isDisabled = disabledCondition ? disabledCondition(item) : false;
          const isHighlighted = shouldHighlight(item.hotKey);
          
          return (
            <div 
              key={index}
              className={`menu-item menu-item-${sectionId.toLowerCase()} ${isDisabled ? 'disabled' : ''}`}
              style={{ 
                padding: '10px',
                paddingTop: index === 0 ? '10px' : '12px',
                color: isDisabled ? '#868686' : '#fff',
                cursor: isDisabled ? 'not-allowed' : 'pointer',
                borderTop: index === 0 ? 'none' : '1px solid rgba(255,255,255,0.1)',
                transition: 'all 0.3s ease',
                fontSize: '14px',
                fontWeight: 400,
                backgroundColor: isHighlighted ? 'rgba(255,255,255,0.2)' : 'transparent',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
              onClick={() => {
                if (!isDisabled) {
                  if (onMenuItemClick) {
                    onMenuItemClick(item, sectionId);
                  } else if (performTripFunction) {
                    performTripFunction(item, sectionId);
                  }
                }
              }}
              title={isDisabled && disabledMessage ? disabledMessage : item.functionText}
            >
              <span>{item.functionText}</span>
              {isHighlighted && item.hotKey && (
                <span style={{ 
                  marginLeft: '8px',
                  padding: '2px 6px',
                  backgroundColor: 'rgba(255,255,255,0.1)',
                  borderRadius: '4px',
                  fontSize: '12px'
                }}>
                  {getHotkeyDisplay(item.hotKey)}
                </span>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const renderDrawerContent = () => (
    <div style={{ 
      height: 'calc(100vh - 64px)', 
      backgroundColor: '#1a1a1a', 
      overflowY: 'auto', 
      width: isDrawerOpen ? '200px' : '60px',
      position: 'fixed',
      left: 0,
      top: '64px',
      bottom: 0,
      zIndex: 100,
      pointerEvents: 'auto'
    }}>
      <IconButton 
        onClick={toggleDrawer}
        style={{ 
          width: '100%', 
          color: '#fff', 
          justifyContent: isDrawerOpen ? 'flex-end' : 'center',
          padding: '10px'
        }}
      >
        {isDrawerOpen ? <ChevronLeftIcon /> : <MenuIcon />}
      </IconButton>
      {isDrawerOpen ? (
        <>
          {navigation.map((section) => (
            <div key={section.id}>
              <div 
                style={{ 
                  padding: '10px', 
                  color: '#fff', 
                  backgroundColor: getColorForSection(section.id),
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
                onClick={() => toggleSectionVisibility(section.id)}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <i className={section.icon} aria-hidden="true"></i>
                  <span style={{ marginLeft: '10px'}}>{section.text}</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '5px', fontWeight: 'bold' }}>
                    ({section.options ? section.options.length : 0})
                  </span>
                  {sectionVisibility[section.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
              </div>
              {sectionVisibility[section.id] && renderSectionContent(section.id)}
            </div>
          ))}
        </>
      ) : (
        <>
          {navigation.map((section) => (
            <div 
              key={section.id}
              id={section.id}
              style={{ 
                padding: '10px', 
                color: '#fff', 
                backgroundColor: getColorForSection(section.id),
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              onMouseEnter={() => handleSectionHover(section.id)}
              onMouseLeave={handleSectionLeave}
            >
              <i className={section.icon} aria-hidden="true"></i>
            </div>
          ))}
        </>
      )}
    </div>
  );

  return (
    <>
      <div
        style={{
          width: isDrawerOpen ? '200px' : '60px',
          transition: 'width 0.3s',
          position: 'relative',
        }}
      >
        {renderDrawerContent()}
      </div>
      {!isDrawerOpen && isPopoverVisible && hoveredSection && ReactDOM.createPortal(
        <div
          style={{
            position: 'fixed',
            top: `${popoverPosition.top}px`,
            left: `${popoverPosition.left}px`,
            zIndex: 9999999,
            backgroundColor: getColorForSection(hoveredSection, true),
            padding: '20px',
            minWidth: '200px',
            boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
            borderRadius: '4px',
            transition: 'opacity 0.3s',
            opacity: isPopoverVisible ? 1 : 0,
            pointerEvents: 'auto',
            width: 'fit-content',
            overflow: 'hidden',
          }}
          onMouseEnter={handlePopoverEnter}
          onMouseLeave={handlePopoverLeave}
        >
          <h3 style={{ color: 'white', margin: 0, marginBottom: '10px' }}>
            {hoveredSection}
          </h3>
          {renderSectionContent(hoveredSection)}
        </div>,
        document.body
      )}
    </>
  );
};

export default SideMenu;