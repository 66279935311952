import React, { useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import { withRouter } from "react-router";
import Copyright from "../Copyright";
import httpRequest from "../HttpService";

import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";

import Select from "@material-ui/core/Select";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import DriverMessageList from "./components/DriverMessageList";
import MessageList from "./components/MessageList";
import DriverList from "./components/DriverList";
import TripList from "./components/TripList";
import DriverMap from "./components/DriverMap";
import OneDriverMap from "./components/OneDriverMap";
import AllDriversMap from "./components/AllDriversMap";
import AllMap from "./components/AllMap";
import { Grid } from "@material-ui/core";
import mapLegend from "./components/imgs/INX Map Legends.jpg";
import NoticeEmitter from "../NoticeUtil";

import NotListedLocationIcon from "@mui/icons-material/NotListedLocation";
import ClearIcon from "@mui/icons-material/Clear";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import HistoryIcon from '@mui/icons-material/History';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';

import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider,} from "@material-ui/pickers";

//??????? comapnyID
class Driver extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessToken: localStorage.getItem("access_token"),
      //companyID: 1,
      showDriversList: false,
      showTripsList: false,
      showPicks: true,
      showDelivery: true,
      showDrivers: true,
      showLines: true,
      showBreadcrumbPoints: false,
      boards: [],
      boardID: 2, //2
      currentDriver: this.props.match.params.drNum? this.props.match.params.drNum: "",
      sendMsgDriver: this.props.match.params.drNum? this.props.match.params.drNum: "",
      messageToSend: "",
      endDate: new Date(),
      searchMonth: new Date(),
      drvGroups: [],
      drvGroupID: -1,
      drivers: [],
      trips: [],
      allMapPoints: [],
      hovered: false,
      breadcrumbsStartTime: new Date(),
      breadcrumbsEndTime: new Date(),
      hoveredDriverInfo: "",
      hoveredDriverInfoPhone: "",
      hoveredDriverInfoEmail: "",
      hoveredDriverInfoGeoMins: "",
      hoveredDriverInfoRefused: "",
      hoveredDriverInfoBurns: "",
      hoveredDriverInfoTripCount: "",
      hoveredDriverInfoTrips: "",
      anchorEI: null,
      open: Boolean(null),
      driverMapPoints: [],
      exceptionMapPoints: [],
      tripMapPoints: [],
      IsIncludedDrvParams: this.props.match.params.drNum ? true : false,
      allMapPoints_drv: [],

    };
    if (this.props.match.params.drNum) {
      console.log("driver page - drNum is " + this.props.match.params.drNum);
    }
  }


  componentWillUnmount() {
    NoticeEmitter.removeListener('currentCompanyID', this.currentCompanyIDCallBack);
    NoticeEmitter.removeListener('freshAllDriverMap', this.freshAllDriverMapCallBack);
  }

  componentDidMount = () => {
    let token = localStorage.getItem("access_token");
    if (token === undefined || token === null) window.location.href = "/";

    //console.log("props companyID is " + this.props.companyID);
    //console.log("accesstoken is " + token);
    const driverList = [];
    httpRequest.getDriverStats(this.state.accessToken, this.props.companyID).then(async (body) => {
        console.log(JSON.stringify(body.data[0]));
        await body.data.forEach((value) => {
          driverList.push(value);
        });
        this.setState({ drivers: driverList });
      });

    const drvGroupList = [];
    httpRequest.getDriverGroup(this.state.accessToken).then(async (body) => {
      console.log("getDriverGroup-" + JSON.stringify(body.data));
      drvGroupList.push({
        driverGroupID: -1,
        descr: "Select driver group",
      });
      await body.data.forEach((value) => {
        drvGroupList.push(value);
      });
      this.setState({ drvGroups: drvGroupList });
      
    });

    const boardList = [];
    httpRequest.getDispatchBoards(this.state.accessToken).then(async (body) => {
      console.log("getDispatchBoards-" + JSON.stringify(body.data));
      this.setState({ boards: body.data });
      if(body.data[0]?.boardID)
         this.setState({boardID: body.data[0]?.boardID })
      this.getTripList(this.state.boardID);
    });

    
   // this.getTripList(this.state.boardID);
    NoticeEmitter.addListener("currentCompanyID",this.currentCompanyIDCallBack);
    NoticeEmitter.addListener("freshAllDriverMap",this.freshAllDriverMapCallBack);

    this.pollData();

    // refresh data then show driver information from (json)
    if(this.state.IsIncludedDrvParams){
      setTimeout(() => { console.log("IsIncludedDrvParams timeout"); this.pollData();}, 5000);
    }

    const interval = setInterval(() => {this.pollData();}, 60000);
    return () => {
      clearInterval(interval);
      NoticeEmitter.removeAllListeners();
    };
  
  };

  currentCompanyIDCallBack = (data) => {
    console.log("NoticeEmitter currentCompany-" + data);
    if (this.state.currentDriver === "") window.location.href = "/driver";
  }

  freshAllDriverMapCallBack = (data) => {
    console.log("NoticeEmitter freshAllDriverMap-" + data);
    this.pollData();
  }

  pollData = () => {
    this.getTripList(this.state.boardID);
    this.getMapPointsByDriverGroup(this.state.drvGroupID);
    httpRequest.getMapPointsAll(this.state.accessToken, this.props.companyID).then(async (body) => {
        //console.log(this.state.drvGroupID + "===getMapPointsAll====" + body.data.length);
        this.setState({ allMapPoints_drv: body.data });
    });

    // if(this.props.match.params.drNum)
    //    this.setDriverMapPin(this.props.match.params.drNum);

    if (this.state.currentDriver !== "")
      this.setDriverMapPin(this.state.currentDriver);
  };

  getMapPointsByDriverGroup = (drvGroupID) => {
    if (drvGroupID === -1) {
      httpRequest.getMapPointsAll(this.state.accessToken, this.props.companyID).then(async (body) => {
          console.log( drvGroupID +  "**************getMapPointsAll***********" +  body.data.length);
          this.setState({ allMapPoints: body.data });
        });
    } else {
      httpRequest.getMapPointsByDrvGroup(this.state.accessToken,this.props.companyID, drvGroupID).then(async (body) => {
          console.log( drvGroupID + "=======getMapPointsByDrvGroup=====" + body.data.length);
          this.setState({ allMapPoints: body.data });
      });
    }
  };

  handleDriverUpdate = (event) => {
    console.log("handleDriverUpdate-" + event.target.value);
    if (this.props.match.params.drNum) {
      if (event.target.value.length === 3)
        window.location.href = "/driver/" + event.target.value;
    } else {
      if (event.target.value.length === 3) {
        this.setDriverMapPin(event.target.value);
        this.setState({ currentDriver: event.target.value });
        this.setState({ sendMsgDriver: event.target.value });
      }
      if (event.target.value.length === 0) {
        this.setState({ currentDriver: "" });
        this.setState({ sendMsgDriver: "" });
      }
    }
  };

  updateDriverNumber = (drNum) => {
    this.setState({ currentDriver: drNum });
    this.setDriverMapPin(drNum);
  };

  handleDriverClick = (event) => {
    this.setState({ showDriversList: event.target.checked });
  };

  handleTripClick = (event) => {
    this.setState({ showTripsList: event.target.checked });
  };

  handlePicksClick = (event) => {
    console.log("handlePicksClick--" + event.target.checked);
    this.setState({ showPicks: event.target.checked });
  };

  handleDeliveryClick = (event) => {
    console.log("handleDeliveryClick--" + event.target.checked);
    this.setState({ showDelivery: event.target.checked });
  };

  handleDriversClick = (event) => {
    console.log("handleDriversClickevent--" + event.target.checked);
    this.setState({ showDrivers: event.target.checked });
  };
  handleLinesClick = (event) => {
    console.log("handleLinesClickevent--" + event.target.checked);
    this.setState({ showLines: event.target.checked });
  };
  handleBoardIDSelect = (event) => {
    console.log("========handleBoardIDSelect======" + event.target.value);
    this.setState({ boardID: event.target.value });
    this.getTripList(event.target.value);
  };

  handleGroupIDSelect = (event) => {
    console.log("handleGroupIDSelect--" + event.target.value);
    this.setState({ drvGroupID: event.target.value });
    this.getMapPointsByDriverGroup(event.target.value);
  };

  getTripList = (boardID) => {
    const tripList = [];
    httpRequest.getTripsDispatch(this.state.accessToken, boardID).then(async (body) => {
        if (body.data.length !== 0) {
          //var id = 0;
          await body.data.forEach((trip) => {
            trip.SER_AREA = trip.SER_AREA + "-" + trip.SER_TIME;
            if (trip.priorJobID === null) {
              trip.priorJobID = trip.NextJobID;
            }
            trip.id = tripList.length;
            trip.assignedDriver = "";
            trip.clear = "";

            // tripList.push({
            //     ...trip,
            //     id:id,
            //     assignedDriver:"",
            //     clear:""
            // });           
            tripList.push(trip);
          });
          //console.log("=====getTripList trip=====  is " +JSON.stringify(tripList));
          tripList.sort((a, b) => (a.dtDCT < b.dtDCT ? -1 : 1));
          this.setState({ trips: tripList });
        } else this.setState({ trips: [] });
      });
  };

  handlerDriverPinClick = (driverNumber) => {
    console.log("handlerDriverPinClick-" + driverNumber);
    this.setState({ currentDriver: driverNumber });
    this.setDriverMapPin(driverNumber);
    this.setState({ sendMsgDriver: driverNumber });
  };

  setDriverMapPin = (drNum) => {
    const drvPointsList = [];
    const tripPointsList = [];
    const exceptionPointsList = [];

    this.state.allMapPoints_drv.forEach((value) => {
      if (value.mapImage === "Car" && value.drNum === drNum) {
        if (value.exception !== null && value.exception === true)
          exceptionPointsList.push(value);
        else drvPointsList.push(value);        
        this.handleDriverMarkerHover(drNum, value.json);
      } else if (value.drNum === drNum) {
        tripPointsList.push(value);
      }
    });
    this.setState({ tripMapPoints: tripPointsList });
    this.setState({ exceptionMapPoints: exceptionPointsList });
    this.setState({ driverMapPoints: drvPointsList });
  };

  allShowClick = (event) => {
    this.setState({ currentDriver: "" });
    this.setState({ sendMsgDriver: "" });
    document.getElementById("currentDrv").value = "";
    if (this.props.match.params.drNum) window.location.href = "/driver";
  };


  driverBreadcrumbPointsClick = (event) => {
      this.setState({showBreadcrumbPoints: true});
  };

  driverBreadcrumbPointsOffClick = (event) => {
    this.setState({showBreadcrumbPoints: false});
  };

  handleSendDriverMsgClick = (event) => {
    //console.log("handleSendDriverMsgClicke---------" + this.state.sendMsgDriver + "---" + this.state.messageToSend);
    httpRequest.sendDriverMessage(this.state.accessToken,this.state.sendMsgDriver,this.props.companyID, undefined, this.state.messageToSend);
    document.getElementById("msgDrv").value = "";
    document.getElementById("msgText").value = "";
  };

  handleSendMsgDriverChange = (event) => {
    this.setState({ sendMsgDriver: event.target.value });
  };

  handleMessageChange = (event) => {
    this.setState({ messageToSend: event.target.value });
  };

  handleDriverMarkerHover = (drNum, json) => {
    //console.log("driver json -" + JSON.stringify(json));
    json = JSON.parse(json);
    var msg = "";
    var msgPhone = "";
    var msgEmail = "";
    var msgGeoMins = "";
    var msgRefused = "";
    var msgBurns = "";
    var msgTripCount = "";
    var msgTrips = "";

    if (drNum !== null) {
      msg = drNum + "  " + json[0].firstName;
      msgPhone = json[0].ContactPhone;
      msgEmail = json[0].contactEmail;
      msgGeoMins = json[0].GeoMinutes;
      msgRefused = json[0].refused;
      msgBurns = json[0].burns;
      msgTripCount = json[0].tripCount;
      msgTrips = json[0].trips;

      this.setState({ hoveredDriverInfo: msg });
      this.setState({ hoveredDriverInfoPhone: msgPhone });
      this.setState({ hoveredDriverInfoEmail: msgEmail });
      this.setState({ hoveredDriverInfoGeoMins: msgGeoMins });
      this.setState({ hoveredDriverInfoRefused: msgRefused });
      this.setState({ hoveredDriverInfoBurns: msgBurns });
      this.setState({ hoveredDriverInfoTripCount: msgTripCount });
      this.setState({ hoveredDriverInfoTrips: msgTrips });
    }
    this.setState({ hovered: true });
  };

  renderDriverInfo() {
    //console.log("Rendering driver info");
    return this.state.hovered ? (
      <div className="appBarDark">
        <div className="statsWrapper statsWrapperDriver">
          <div className="statBox statBoxPrimary">
            <div className="titleBox">driver#</div>
            <div className="descBox">{this.state.hoveredDriverInfo}</div>
          </div>
          <div className="statBox statBoxPrimary">
            <div className="titleBox">phone</div>
            <div className="descBox">{this.state.hoveredDriverInfoPhone}</div>
          </div>
          <div className="statBox statBoxPrimary">
            <div className="titleBox">email</div>
            <div className="descBox">{this.state.hoveredDriverInfoEmail}</div>
          </div>
          <div className="statBox statBoxSecondary">
            <div className="titleBox">geo mins</div>
            <div className="descBox">{this.state.hoveredDriverInfoGeoMins}</div>
          </div>
          <div className="statBox statBoxDanger">
            <div className="titleBox">refused</div>
            <div className="descBox">{this.state.hoveredDriverInfoRefused}</div>
          </div>
          <div className="statBox statBoxWarning">
            <div className="titleBox">burns</div>
            <div className="descBox">{this.state.hoveredDriverInfoBurns}</div>
          </div>
          <div className="statBox statBoxSecondary">
            <div className="titleBox">tripcount</div>
            <div className="descBox">
              {this.state.hoveredDriverInfoTripCount}
            </div>
          </div>
          <div className="statBox statBoxSecondary">
            <div className="titleBox">trips</div>
            <div className="descBox">{this.state.hoveredDriverInfoTrips}</div>
          </div>
        </div>
      </div>
    ) : (
      <div></div>
    );
  }

  handlePopoverOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
    this.setState({ open: Boolean(event.currentTarget) });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
    this.setState({ open: Boolean(null) });
  };

  render() {
    return (
      <div className="root">
        <AppBar position="static" className="appBarDark" style={{ overflow: "auto", padding: "15px 0.5%" }}>
          <Grid container spacing={0}>
            <Grid item md={12} lg={6} style={{ display: "flex" }}>
              {/* <TextField label="Driver #" variant="outlined" size="small" style={{marginRight: '10px', width: 90}} onChange={this.handleDriverUpdate} inputProps={{ maxLength: 3 }} value={this.state.currentDriver}/> */}
              <div style={{ paddingRight: "10px" }}>
                <Tooltip title="Show All">
                  <IconButton className="btnPrimary" aria-label="Show All" onClick={this.allShowClick}>
                    
                  {!this.state.IsIncludedDrvParams && this.state.currentDriver !== "" ? (  
                    <ClearIcon />
                  ) : (
              <></>
            )}
                  </IconButton>
                </Tooltip>
              </div>
              <TextField id="currentDrv" type="number"  label="Driver #"  variant="outlined"  size="small"   style={{ marginRight: "10px", width: 90 }}  onChange={this.handleDriverUpdate} inputProps={{ maxLength: 3 }}/>
              <FormControl variant="outlined" size="small" style={{ marginRight: "10px", width: 140 }}>
                <InputLabel>Board Name</InputLabel>
                <Select value={this.state.boardID}  label="Board Name"   onChange={this.handleBoardIDSelect}>
                  {this.state.boards.map((item) => (
                    <MenuItem  key={item.boardID}  value={item.boardID}>
                      {item.boardName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined"  size="small"  style={{ marginRight: "10px", width: 200 }}>
                <InputLabel>DriverGroup</InputLabel>
                <Select label="DriverGroup" value={this.state.drvGroupID} onChange={this.handleGroupIDSelect}>
                  {this.state.drvGroups.map((group) => (
                    <MenuItem key={group.driverGroupID} value={group.driverGroupID}>
                      {group.descr}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              { ((this.state.IsIncludedDrvParams && this.state.currentDriver !== "" ) ||
                (!this.state.IsIncludedDrvParams && this.state.currentDriver !== "")) && !this.state.showBreadcrumbPoints &&
                <div style={{ paddingRight: "10px" }}>
                <Tooltip title="Driver Breadcrumb Points">
                  <IconButton className="btnPrimary" aria-label="BreadcrumbPoints" onClick={this.driverBreadcrumbPointsClick}>
                    <HistoryIcon />
                  </IconButton>
                </Tooltip>
              </div>}
              { ((this.state.IsIncludedDrvParams && this.state.currentDriver !== "" ) ||
                (!this.state.IsIncludedDrvParams && this.state.currentDriver !== "")) && this.state.showBreadcrumbPoints &&
                <div style={{ paddingRight: "10px" }}>
                <Tooltip title="Driver Breadcrumb Points">
                  <IconButton className="btnPrimary" aria-label="BreadcrumbPoints" onClick={this.driverBreadcrumbPointsOffClick}>
                    <HistoryToggleOffIcon />
                  </IconButton>
                </Tooltip>
              </div>}

              {((this.state.IsIncludedDrvParams && this.state.currentDriver !== "" ) ||
                (!this.state.IsIncludedDrvParams && this.state.currentDriver !== ""))&& (
                  <div style={{ paddingRight: "10px" }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                      name="bcStart"
                      className="editableJobField"
                      format="yyyy/MM/dd HH:mm"
                      label={<Typography>Start</Typography>}
                      value={this.state.breadcrumbsStartTime}
                      onChange={(input) => {                        
                        this.setState({ breadcrumbsStartTime: input });
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  </div>
                )}

              {((this.state.IsIncludedDrvParams && this.state.currentDriver !== "" ) ||
                (!this.state.IsIncludedDrvParams && this.state.currentDriver !== "")) && (
                  <div style={{ paddingRight: "10px" }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                      name="bcEnd"
                      className="editableJobField"
                      format="yyyy/MM/dd HH:mm"
                      label={<Typography>End</Typography>}
                      value={this.state.breadcrumbsEndTime}
                      onChange={(input) => {                        
                        this.setState({ breadcrumbsEndTime: input });
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  </div>
                )}                 
            </Grid>
            <Grid item md={12} lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
              <div className="mapTogglesWrapper">
                <FormGroup style={{ display: "inline", color: "black" }}>
                  <FormControlLabel
                    control={<Switch color="primary" size="small" onChange={this.handleDriverClick} checked={this.state.showDriversList}/>}
                    label="Drivers List"
                  />
                  <FormControlLabel
                    control={<Switch color="primary" size="small" onChange={this.handleTripClick} checked={this.state.showTripsList}/>}
                    label="Trips List"
                  />
                  <FormControlLabel
                    control={<Switch color="primary" size="small" onChange={this.handlePicksClick} checked={this.state.showPicks}/>}
                    label="Picks"
                  />
                  <FormControlLabel
                    control={<Switch color="primary" size="small" onChange={this.handleDeliveryClick}  checked={this.state.showDelivery}/>}
                    label="Deliveries"
                  />
                  <FormControlLabel
                    control={<Switch color="primary" size="small" onChange={this.handleDriversClick}  checked={this.state.showDrivers}/> }
                    label="Drivers"
                  />
                  <FormControlLabel
                    control={<Switch color="primary" size="small" onChange={this.handleLinesClick}  checked={this.state.showLines}/> }
                    label="Lines"
                  />
                </FormGroup>
                <Tooltip title="Map Legend">
                  <IconButton   className="btnPrimary"  aria-label="Map Legend"  onClick={this.handlePopoverOpen}>
                    <NotListedLocationIcon />
                  </IconButton>
                </Tooltip>
                <Popover
                  open={this.state.open}
                  className="popoverDark"
                  anchorEl={this.state.anchorEl}
                  onClose={this.handlePopoverClose}
                  anchorOrigin={{ vertical: "bottom",  horizontal: "right",}}
                  transformOrigin={{ vertical: "top", horizontal: "right", }}
                >
                  <div>
                    <img src={mapLegend}  style={{ width: "577px", display: "block", height: "auto",}} alt="Map Legend"/>
                  </div>
                </Popover>
              </div>
            </Grid>
          </Grid>
        </AppBar>

        {this.state.showDriversList && (
          <DriverList  driverList={this.state.drivers} updateDriver={this.updateDriverNumber} companyID={this.props.companyID} accessToken={this.state.accessToken}/>
        )}
        {this.state.showTripsList && (
          <TripList  boardID={this.state.boardID} tripList={this.state.trips} companyID={this.props.companyID} accessToken={this.state.accessToken}/>
        )}
        {this.renderDriverInfo()}
        <Grid  container  direction="row"  justifyContent="center"  alignItems="start"  spacing={0}  sm={12}>
          <Grid item xs={12} md={8}>
            {this.state.currentDriver === "" && this.state.allMapPoints.length > 0 ? (
              <AllMap
                boardID={this.state.boardID}
                tripList={this.state.trips}
                companyID={this.props.companyID}
                mapPoints={this.state.allMapPoints}
                accessToken={this.state.accessToken}
                handlerDriverPinClick={this.handlerDriverPinClick}
                hoverHandler={this.handleDriverMarkerHover}
                IsShowDrivers={this.state.showDrivers}
                IsShowPickup={this.state.showPicks}
                IsShowDelivery={this.state.showDelivery}            
                IsShowLines={this.state.showLines}    
              />
            ) : (
              <></>
            )}

            {/* 
                    {this.state.IsIncludedDrvParams && this.state.currentDriver !=='' ? <OneDriverMap boardID={this.state.boardID} tripList={this.state.trips} companyID={this.props.companyID} tripPinsList={this.state.tripMapPoints}
                    accessToken={this.state.accessToken} currentDriver={this.state.currentDriver} hoverHandler={this.handleDriverMarkerHover}/> :<></>} 
                    {!this.state.IsIncludedDrvParams && (this.state.currentDriver !=='') && (this.state.driverMapPoints.length>0 || this.state.exceptionMapPoints.length>0)? <DriverMap  driverPinsList={this.state.driverMapPoints} 
                         tripPinsList={this.state.tripMapPoints} exceptionPinsList={this.state.exceptionMapPoints} mapPoints={this.state.allMapPoints}
                         currentDriver={this.state.currentDriver} hoverHandler={this.handleDriverMarkerHover}/> :<></>}   */}

            {this.state.IsIncludedDrvParams && this.state.currentDriver !== "" ? (
              <OneDriverMap
                boardID={this.state.boardID}
                tripList={this.state.trips}
                companyID={this.props.companyID}
                tripPinsList={this.state.tripMapPoints}
                accessToken={this.state.accessToken}
                currentDriver={this.state.currentDriver}
                hoverHandler={this.handleDriverMarkerHover}
                IsShowPickup={this.state.showPicks}
                IsShowDelivery={this.state.showDelivery}
                IsShowBreadcrumbPoints={this.state.showBreadcrumbPoints}
                startTime = {this.state.breadcrumbsStartTime}
                endTime = {this.state.breadcrumbsEndTime}
              />
            ) : (
              <></>
            )}

            {!this.state.IsIncludedDrvParams && this.state.currentDriver !== "" ? (
              <DriverMap
                driverPinsList={this.state.driverMapPoints}
                tripPinsList={this.state.tripMapPoints}
                exceptionPinsList={this.state.exceptionMapPoints}
                mapPoints={this.state.allMapPoints}
                currentDriver={this.state.currentDriver}
                hoverHandler={this.handleDriverMarkerHover}
                IsShowPickup={this.state.showPicks}
                IsShowDelivery={this.state.showDelivery}
                accessToken={this.state.accessToken}
                IsShowBreadcrumbPoints={this.state.showBreadcrumbPoints}
                startTime = {this.state.breadcrumbsStartTime}
                endTime = {this.state.breadcrumbsEndTime}
              />
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container direction="column"  sm={12} style={{ padding: "10px" }}>
              <Grid item>
                <Box display="flex"   border={0} className="darkForm" style={{ flexwrap: "nowrap", marginBottom: "10px" }}  sx={{ height: "auto" }}>
                  <TextField  id="msgDrv"  label="Driver#"  variant="outlined"   size="small"   style={{ marginRight: "10px", width: "20%" }}
                    value={this.state.sendMsgDriver}  onChange={this.handleSendMsgDriverChange}  inputProps={{ maxLength: 3 }}/>
                  <TextField  id="msgText" label="Send Message"  variant="outlined"  size="small"  style={{ width: "70%" }}   onBlur={this.handleMessageChange}/>
                  {/* <Button variant='contained' className='btnPrimary' color='primary' style={{marginLeft: '5px'}} onClick={this.handleSendDriverMsgClick}>Send</Button> */}
                  <div style={{ marginLeft: "5px" }}>
                    <Tooltip title="Send">
                      <IconButton  className="btnPrimary"  aria-label="Send"  onClick={this.handleSendDriverMsgClick}><SendIcon /></IconButton>
                    </Tooltip>
                  </div>
                </Box>
              </Grid>
              <Grid item>
                {this.state.currentDriver === "" ? (
                  <MessageList  updateDriver={this.updateDriverNumber}  companyID={this.props.companyID} accessToken={this.state.accessToken}/>
                ) : (
                  <></>
                )}
                {this.state.currentDriver !== "" ? (
                  <DriverMessageList  currentDriver={this.state.currentDriver} companyID={this.props.companyID}  accessToken={this.state.accessToken}/>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <footer><Copyright  textColor='white'/></footer>
      </div>
    );
  }
}

export default withRouter(Driver);
