import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
//import TextInput from "../../../components/inputFields/TextInput";
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import CustomIconButton from "../../../components/inputFields/CustomIconButton.js";
import Typography from '@mui/material/Typography';
import "animate.css/animate.css";

const TagNumberFieldsEditable = (props) => {
  const minNum = props.minNum;
  const maxNum = props.maxNum;
  const [numValue, setNumValue] = useState(props.value || 0);
  const [tipText, setTipText] = useState(props.prompt);
  const [warningMsg, setWarningMsg] = useState('');

  useEffect(() => {
    if (minNum !== undefined || maxNum !== undefined) {
      if (minNum !== undefined && maxNum !== undefined) {
        setNumValue(Math.min(Math.max(props.value, minNum), maxNum));
        setTipText(`${props.prompt || ''} Number must be between ${minNum} and ${maxNum}.`);
      } else if (minNum !== undefined) {
        setNumValue(Math.max(props.value, minNum));
        setTipText(`${props.prompt || ''} Number must be greater than ${minNum}.`);
      } else if (maxNum !== undefined) {
        setNumValue(Math.min(props.value, maxNum));
        setTipText(`${props.prompt || ''} Number must be less than ${maxNum}.`);
      }
    } else {
      setNumValue(props.value);
    }
  }, [props.value, minNum, maxNum, props.prompt]);

  useEffect(() => {
    const num = parseInt(numValue);
    if (num > maxNum) setWarningMsg(`Number must be less than ${maxNum}`);
    else if (num < minNum) setWarningMsg(`Number must be greater than ${minNum}`);
    else setWarningMsg('');
  }, [numValue, minNum, maxNum]);

  const handleChange = (event) => {
    const value = event.target.value;
    if (value === "") {
      setNumValue(0);
      props.onChange({ name: props.param, value: 0 });
    } else {
      try {
        const num = parseInt(value);
        const clampedNum = Math.min(Math.max(num, minNum || -Infinity), maxNum || Infinity);
        setNumValue(clampedNum);
        props.onChange({ name: props.param, value: clampedNum });
      } catch (ex) {
        setNumValue(value);
      }
    }
  };

  const clearNum = () => {
    setNumValue(0);
    props.onChange({ name: props.param, value: 0 });
  };

  return (
    <Grid container direction="row"  style={{ margin: "0", width: "100%"}} spacing={0} sm={10}>
      <Grid item sm={10}>
        <Tooltip title={tipText} placement="top" arrow>
          <div className="tagField">
          <TextField
            id={props.param}
            type="number"
            label={props.label}
            hideLabel={props.hideLabel}
            labelSize={props.labelSize}
            variant="outlined"
            size="large"
            color={props.color}
            value={numValue}
            onChange={handleChange}
            inputProps={{
              min: minNum,
              max: maxNum,
            }}
          />
          </div>
        </Tooltip>
        {warningMsg && <Typography style={{ marginTop: 5 }} color="error">{warningMsg}</Typography>}
      </Grid>
      <Grid item sm={2}>
        <CustomIconButton
          icon={props.icon || "ClearIcon"}
          tooltip={props.iconTooltip || "Clear"}
          onClick={clearNum}
          color={props.color}
        />
      </Grid>
    </Grid>
  );
};

export default TagNumberFieldsEditable;