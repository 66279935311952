import React, { useState, useEffect } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';

const RefreshTimer = ({ onRefresh, interval = 60000, isEnabled, onToggle }) => {
  const [timeLeft, setTimeLeft] = useState(interval / 1000);

  useEffect(() => {
    let timerId = null;
    
    if (isEnabled) {
      timerId = setInterval(() => {
        setTimeLeft(prev => {
          if (prev <= 1) {
            onRefresh();
            return interval / 1000;
          }
          return prev - 1;
        });
      }, 1000);
    }
    
    return () => {
      if (timerId) clearInterval(timerId);
    };
  }, [isEnabled, interval, onRefresh]);

  const handleManualRefresh = () => {
    onRefresh();
    setTimeLeft(interval / 1000);
  };

  const buttonStyles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px'
    },
    base: {
      padding: '8px 16px',
      margin: '0 8px',
      borderRadius: '4px',
      border: '2px solid',
      cursor: 'pointer',
      fontWeight: 'bold',
      transition: 'all 0.3s ease',
      position: 'relative',
      overflow: 'hidden',
      color: isEnabled ? 'white' : '#13ACB1',
      borderColor: '#13ACB1',
      backgroundColor: 'transparent'
    },
    progress: {
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: `${(timeLeft / (interval/1000)) * 100}%`,
      backgroundColor: isEnabled ? '#13ACB1' : 'transparent',
      transition: 'width 1s linear'
    },
    refreshButton: {
      color: 'white',
      padding: '6px',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)'
      }
    }
  };

  return (
    <div style={buttonStyles.container}>
      <button
        onClick={() => onToggle(!isEnabled)}
        style={buttonStyles.base}
      >
        <div style={buttonStyles.progress} />
        <span style={{ position: 'relative', zIndex: 1 }}>
          AUTO-REFRESH: {Math.floor(timeLeft)}s
        </span>
      </button>
      <Tooltip title="Refresh now">
        <IconButton 
          onClick={handleManualRefresh}
          style={buttonStyles.refreshButton}
        >
          <Refresh />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default RefreshTimer;