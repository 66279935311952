import React from "react";
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useHistory,useParams  } from 'react-router-dom';
import Popover from "@mui/material/Popover";
import Copyright from "../Copyright";
import httpRequest from "../HttpService";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
//import TextField from "@material-ui/core/TextField";
import { TextField } from '@mui/material';
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import DriverMessageList from "./components/DriverMessageList";
import MessageList from "./components/MessageList";
import DriverList from "./components/DriverList";
import TripList from "./components/TripList";
// import DriverMap from "./components/DriverMap";
import OneDriverMap from "./components/OneDriverMap";
import AllMap from "./components/AllMap";
import { Grid } from "@material-ui/core";
import mapLegend from "./components/imgs/INX Map Legends.jpg";
import NoticeEmitter from "../NoticeUtil";
import NotListedLocationIcon from "@mui/icons-material/NotListedLocation";
import RefreshIcon from "@mui/icons-material/Refresh";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import HistoryIcon from '@mui/icons-material/History';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider,} from "@material-ui/pickers";
import { letterSpacing } from "@mui/system";
import { debounce } from 'lodash';



export default function DriverPanel(props) { 
    const history = useHistory(); 
    const {drNum}= useParams()

    const accessToken = localStorage.getItem("access_token");
    const [showDriversList, setShowDriversList] = useState(false)
    const [showTripsList, setShowTripsList] = useState(false)
    const [showPicks, setShowPicks] = useState(true)
    const [showDelivery, setShowDelivery] = useState(true)
    const [showDrivers, setShowDrivers] = useState(true)
    const [showBreadcrumbPoints, setShowBreadcrumbPoints] = useState(false)
    const [boards, setBoards] = useState([])
    const [boardID, setBoardID] = useState(2)
    const [currentDriver, setCurrentDriver] = useState(drNum ? drNum : "") 
    const [sendMsgDriver, setSendMsgDriver] = useState(drNum ? drNum : "") 
    const IsIncludedDrvParams = drNum ? true: false
    const [messageToSend, setMessageToSend] = useState("")  
    const [drvGroups, setDrvGroups] = useState([])
    const [drvGroupID, setDrvGroupID] = useState(-1) 
    const [drivers, setDrivers] = useState([])
    const [trips, setTrips] = useState([])
    const [allMapPoints, setAllMapPoints] = useState([])
    const [hovered, setHovered] = useState(false) 
    const [breadcrumbsStartTime, setBreadcrumbsStartTime] = useState(new Date()) 
    const [breadcrumbsEndTime, setBreadcrumbsEndTime] = useState(new Date())   
    const [hoveredDriverDetail, setHoveredDriverDetail] = useState({
        hoveredDriverInfo: "",
        hoveredDriverInfoPhone: "",
        hoveredDriverInfoEmail: "",
        hoveredDriverInfoGeoMins: "",
        hoveredDriverInfoRefused: "",
        hoveredDriverInfoBurns: "",
        hoveredDriverInfoTripCount: "",
        hoveredDriverInfoTrips: ""
    }) 
    const [driverMapPoints, setDriverMapPoints] = useState([])   
    const [exceptionMapPoints, setExceptionMapPoints] = useState([])  
    const [tripMapPoints, setTripMapPoints] = useState([])  
    const [allMapPoints_drv, setAllMapPoints_drv] = useState([])
    const [anchorEI, setAnchorEI] = useState(null)
    const [open, setOpen] = useState(Boolean(null))
    // const [titleTimer, setTitleTimer] = useState(null);  
    const [isLoading, setIsLoading] = useState(true);



    const updateDocumentTitle = () => {   
        if (currentDriver) {
            const driverInfo = drivers.find(driver => driver.drNum === currentDriver);
            if (driverInfo && driverInfo.firstName) {
                document.title = `${currentDriver} (${driverInfo.firstName})`;
            } else {
                document.title = `${currentDriver}`;
            }
        } else {
            document.title = 'Driver Management';
        }
    }

    const fetchMapPoints = useCallback(async () => {
        try {
            const response = await httpRequest.getMapPointsAll(accessToken, props.companyID);
            const mapPoints = response.data;
            setAllMapPoints_drv(mapPoints);
            if (drvGroupID === -1) {
                setAllMapPoints(mapPoints);
            }
        } catch (error) {
            console.error('Error fetching map points:', error);
        }
    }, [accessToken, props.companyID, drvGroupID]);

    const getTripList = useCallback((boardID) => {
        httpRequest.getTripsDispatch(accessToken, boardID).then(async (body) => {
            if (body.data.length !== 0) {          
                const tripList = await Promise.all(body.data.map((trip, index) => ({
                    ...trip,
                    SER_AREA: trip.SER_AREA + "-" + trip.SER_TIME,
                    priorJobID: trip.priorJobID === null ? trip.NextJobID : trip.priorJobID,                    
                    id: index,
                    assignedDriver: "",
                    clear: ""
                })));
                
                tripList.sort((a, b) => (a.dtDCT < b.dtDCT ? -1 : 1));
                setTrips(tripList);          
            } else {
                setTrips([]);
            }
        }).catch(error => {
            console.error('Error fetching trips:', error);
            setTrips([]);
        });
    }, [accessToken]);

     const getMapPointsByDriverGroup = async (drvGroupID) => {
        if (drvGroupID === -1) {
            if (allMapPoints_drv.length > 0) {
                setAllMapPoints(allMapPoints_drv);
            } else {
                await fetchMapPoints();
            }
        } else {
            const response = await httpRequest.getMapPointsByDrvGroup(
                accessToken,
                props.companyID, 
                drvGroupID
            );
            setAllMapPoints(response.data);
        }
    };

    const setDriverMapPin = (drNum) => {
        const drvPointsList = [];
        const tripPointsList = [];
        const exceptionPointsList = [];

        allMapPoints_drv.forEach((value) => {
            if (value.mapImage === "Car" && value.drNum === drNum) {
                if (value.exception !== null && value.exception === true)
                    exceptionPointsList.push(value);
                else drvPointsList.push(value);        
                handleDriverMarkerHover(drNum, value.json);
            } else if (value.drNum === drNum) {
                tripPointsList.push(value);
            }
        });
        setTripMapPoints(tripPointsList)
        setExceptionMapPoints(exceptionPointsList)
        setDriverMapPoints(drvPointsList)
    };

    const pollData = useCallback(async () => {
        try {
            getTripList(boardID);
            if (drvGroupID === -1) {
                await fetchMapPoints();
            } else {
                await getMapPointsByDriverGroup(drvGroupID);
            }
            if (currentDriver !== "") {
                setDriverMapPin(currentDriver);   
            }
        } catch (error) {
            console.error('Error in pollData:', error);
        }
    }, [boardID, drvGroupID, currentDriver, fetchMapPoints, getTripList, getMapPointsByDriverGroup, setDriverMapPin]);

    const init = useCallback(async () => {
        if (initRunning.current) return;
        initRunning.current = true;
        setIsLoading(true);
    
        try {           
            const [driverStats, driverGroups, dispatchBoards] = await Promise.all([
                httpRequest.getDriverStats(accessToken, props.companyID),
                httpRequest.getDriverGroup(accessToken),
                httpRequest.getDispatchBoards(accessToken)
            ]);
            
            // Set all the data first
            setDrivers(driverStats.data || []);
            
            const groupsList = driverGroups.data || [];
            groupsList.unshift({
                driverGroupID: -1,
                descr: "Select driver group"
            });
            setDrvGroups(groupsList);   
            
            const boardsList = dispatchBoards.data || [];
            setBoards(boardsList);
            
            // Only after data is set, update loading state
            setIsLoading(false);
            
            // Then do any actions that depend on the data
            const initialBoardId = boardsList[0]?.boardID || 2;
            setBoardID(initialBoardId);
            await getTripList(initialBoardId);
            updateDocumentTitle();
    
        } catch (error) {
            console.error('Init error:', error);
            setIsLoading(false); // Make sure to enable controls even if there's an error
        } finally {
            initRunning.current = false;
        }
    }, [accessToken, props.companyID, updateDocumentTitle, getTripList]);
    
    // Add debounced pollData
    const debouncedPollData = useMemo(
        () => debounce(async () => {
            if (pollRunning.current) return;
            pollRunning.current = true;
    
            try {
                await getTripList(boardID);
                if (drvGroupID === -1) {
                    await fetchMapPoints();
                } else {
                    await getMapPointsByDriverGroup(drvGroupID);
                }
                if (currentDriver !== "") {
                    setDriverMapPin(currentDriver);   
                }
            } catch (error) {
                console.error('Poll error:', error);
            } finally {
                pollRunning.current = false;
            }
        }, 1000), // 1 second debounce
        [boardID, drvGroupID, currentDriver, fetchMapPoints, getTripList, getMapPointsByDriverGroup, setDriverMapPin]
    );

    const initRunning = useRef(false);
    const pollRunning = useRef(false);
        
    useEffect(() => {   
        let token = localStorage.getItem("access_token");
        if (token === undefined || token === null) window.location.href = "/";
    
        // Initial load
        const loadData = async () => {
            await init();
            await pollData(); // Initial poll after init
        };
        loadData();
    
        // Single poll interval
        const pollInterval = setInterval(pollData, 60000); // Poll every minute
    
        // Define the listener functions separately
        const handleCompanyChange = (data) => {
            if (currentDriver === "") window.location.href = "/driver";
        };
    
        const handleMapRefresh = () => {
            pollData();
        };
    
        // Add listeners
        NoticeEmitter.addListener("currentCompanyID", handleCompanyChange);
        NoticeEmitter.addListener("freshAllDriverMap", handleMapRefresh);
    
        // Cleanup
        return () => {
            clearInterval(pollInterval);
            NoticeEmitter.removeListener("currentCompanyID", handleCompanyChange);
            NoticeEmitter.removeListener("freshAllDriverMap", handleMapRefresh);
        };
    }, []);

    useEffect(() => {   
        //updateDocumentTitle()  
        if(currentDriver.length===3)      
            history.push(`/driver/${currentDriver}`)   
        else  
           history.push(`/driver`)
        setTimeout(() => {updateDocumentTitle();}, 100);  
    }, [currentDriver]);

    const handleDriverUpdate = (event) => {       
        const value = event.target.value;
        if (!isNaN(value) && Number(value) >= 0) {    
            if(value.length > 3){
                event.target.value = event.target.value.substring(0,3)
                return;
            }
            setCurrentDriver(value)
            if (value.length === 3) {            
                setSendMsgDriver(value)
                setDriverMapPin(value)
                //history.push(`/driver/${value}`)           
      
            } else if (value.length === 0) {
                setCurrentDriver("")
                setSendMsgDriver("")
                //history.push('/driver');            
            }
        }
        else{
            event.target.value = event.target.value.substring(0,event.target.value.length-1)            
        }
    };

    const updateDriverNumber = (drNum) => {  
        setCurrentDriver(drNum)
        setDriverMapPin(drNum);
        history.push(`/driver/${drNum}`);   
    };

       const handleBoardIDSelect = (event) => {
        console.log("========handleBoardIDSelect======" + event.target.value);
        setBoardID(event.target.value)
        getTripList(event.target.value)    
    };

    const handleGroupIDSelect = (event) => {
        console.log("handleGroupIDSelect--" + event.target.value);
        setDrvGroupID(event.target.value)        
        getMapPointsByDriverGroup(event.target.value);
    };

    const handlerDriverPinClick = (driverNumber) => {
        console.log("handlerDriverPinClick-" + driverNumber);
        setCurrentDriver(driverNumber)
        setSendMsgDriver(driverNumber)
        setDriverMapPin(driverNumber);
        history.push(`/driver/${driverNumber}`);   
    };

   const allShowClick = () => {
      setCurrentDriver("")
      setSendMsgDriver("")
     // document.getElementById("currentDrv").value = "";      
      history.push('/driver');    
    };
  
    const handleSendDriverMsgClick = async (event) => {
        //console.log("handleSendDriverMsgClicke---------" + sendMsgDriver + "---" + messageToSend);
        const res = await httpRequest.sendDriverMessage(accessToken,sendMsgDriver,props.companyID, undefined, messageToSend);
        setSendMsgDriver('');
        setMessageToSend('');           
    };

    const handleDriverMarkerHover = (drNum, json) => {
        //console.log("driver json -" + JSON.stringify(json));
        json = JSON.parse(json);
        let msg = "";
        let msgPhone = "";
        let msgEmail = "";
        let msgGeoMins = "";
        let msgRefused = "";
        let msgBurns = "";
        let msgTripCount = "";
        let msgTrips = "";

    if (drNum !== null) {
        msg = drNum + "  " + json[0].firstName;
        msgPhone = json[0].ContactPhone;
        msgEmail = json[0].contactEmail;
        msgGeoMins = json[0].GeoMinutes;
        msgRefused = json[0].refused;
        msgBurns = json[0].burns;
        msgTripCount = json[0].tripCount;
        msgTrips = json[0].trips;

        setHoveredDriverDetail((prevState) => {
            return {
                ...prevState,
                hoveredDriverInfo: msg,
                hoveredDriverInfoPhone:msgPhone,
                hoveredDriverInfoEmail: msgEmail,
                hoveredDriverInfoGeoMins: msgGeoMins,
                hoveredDriverInfoRefused: msgRefused,
                hoveredDriverInfoBurns: msgBurns,
                hoveredDriverInfoTripCount: msgTripCount,
                hoveredDriverInfoTrips: msgTrips
            };
        });      
    }
    setHovered(true)   
  };


    const renderDriverInfoField = (style, label, value) =>{
        return(
            <div className={style}>
                <div className="titleBox">{label}</div>
                 <div className="descBox">{value}</div>
            </div> 
        )
    }
    const renderDriverInfo = () =>{
        return hovered ? (
        <div className="appBarDark">
            <div className="statsWrapper statsWrapperDriver">
                {renderDriverInfoField("statBox statBoxPrimary","driver#",hoveredDriverDetail.hoveredDriverInfo)}
                {renderDriverInfoField("statBox statBoxPrimary","phone",hoveredDriverDetail.hoveredDriverInfoPhone)}
                {renderDriverInfoField("statBox statBoxPrimary","email",hoveredDriverDetail.hoveredDriverInfoEmail)}
                {renderDriverInfoField("statBox statBoxSecondary","geo mins",hoveredDriverDetail.hoveredDriverInfoGeoMins)}
                {renderDriverInfoField("statBox statBoxDanger","refused",hoveredDriverDetail.hoveredDriverInfoRefused)}
                {renderDriverInfoField("statBox statBoxWarning","burns",hoveredDriverDetail.hoveredDriverInfoBurns)}
                {renderDriverInfoField("statBox statBoxSecondary","tripcount",hoveredDriverDetail.hoveredDriverInfoTripCount)}         
                {renderDriverInfoField("statBox statBoxSecondary","trips",hoveredDriverDetail.hoveredDriverInfoTrips)} 
            </div>
        </div>
        ) : (<div></div>);
    }

    const handlePopoverOpen = (event) => {
        setAnchorEI( event.currentTarget)
        setOpen(Boolean(event.currentTarget))       
    };

    const handlePopoverClose = () => {
        setAnchorEI( null)
        setOpen(Boolean(null)) 
    };

    return (
        <div className="root">
        <AppBar position="static" className="appBarDark" style={{ overflow: "auto", padding: "15px 0.5%" }}>
            <Grid container spacing={0}>
                <Grid item md={12} lg={6} style={{ display: "flex" }}>
                    <div style={{ paddingRight: "10px" }}>
                    <Tooltip title="Show All">
                        <IconButton className="btnPrimary" aria-label="Show All" onClick={allShowClick}>
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                    </div>
                    <TextField id="currentDrv" value={currentDriver}  type="number"  label="Driver #"  variant="outlined"  size="small"  style={{ marginRight: "10px", width: 90 }}  
                       onChange={(event)=>{ handleDriverUpdate(event)} }/>
                       
                        <FormControl variant="outlined" size="small" style={{ marginRight: "10px", width: 140 }}>
                            <InputLabel id="board-select-label">Board Name</InputLabel>
                            <Select
                                labelId="board-select-label"
                                id="board-select"
                                value={boardID}
                                label="Board Name"
                                onChange={handleBoardIDSelect}
                                disabled={boards.length === 0} // Only disable if no data
                            >
                                {boards.map((item) => (
                                    <MenuItem key={item.boardID} value={item.boardID}>
                                        {item.boardName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl variant="outlined" size="small" style={{ marginRight: "10px", width: 200 }}>
                            <InputLabel id="driver-group-label">DriverGroup</InputLabel>
                            <Select
                                labelId="driver-group-label"
                                id="driver-group-select"
                                value={drvGroupID}
                                label="DriverGroup"
                                onChange={handleGroupIDSelect}
                                disabled={drvGroups.length === 0} // Only disable if no data
                            >
                                {drvGroups.map((group) => (
                                    <MenuItem key={group.driverGroupID} value={group.driverGroupID}>
                                        {group.descr}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                    {currentDriver !== "" && !showBreadcrumbPoints &&
                    <div style={{ paddingRight: "10px" }}>
                        <Tooltip title="Driver Breadcrumb Points">
                            <IconButton className="btnPrimary" aria-label="BreadcrumbPoints" onClick={()=>{setShowBreadcrumbPoints(true)}}>
                                <HistoryIcon />
                            </IconButton>
                        </Tooltip>
                    </div>}
                    {currentDriver !== ""  && showBreadcrumbPoints &&
                    <div style={{ paddingRight: "10px" }}>
                        <Tooltip title="Driver Breadcrumb Points">
                            <IconButton className="btnPrimary" aria-label="BreadcrumbPoints" onClick={()=>{setShowBreadcrumbPoints(false)}}>
                                <HistoryToggleOffIcon />
                            </IconButton>
                        </Tooltip>
                    </div>}
                    {currentDriver !== ""  && 
                    <div style={{ paddingRight: "10px" }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDateTimePicker
                                name="bcStart"
                                className="editableJobField"
                                format="yyyy-MM-dd HH:mm"
                                label={<Typography>Start</Typography>}
                                value={breadcrumbsStartTime}
                                onChange={(input) => {  setBreadcrumbsStartTime(input)}}
                            />
                        </MuiPickersUtilsProvider>
                    </div>}

                    {currentDriver !== ""  && 
                    <div style={{ paddingRight: "10px" }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDateTimePicker
                                name="bcEnd"
                                className="editableJobField"
                                format="yyyy-MM-dd HH:mm"
                                label={<Typography>End</Typography>}
                                value={breadcrumbsEndTime}
                                onChange={(input) => {    setBreadcrumbsEndTime(input) }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>}                 
                </Grid>
                <Grid item md={12} lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div className="mapTogglesWrapper">
                        <FormGroup style={{ display: "inline", color: "black" }}>
                            <FormControlLabel  label="Drivers List"
                                control={<Switch color="primary" size="small" onChange={(event)=>{ setShowDriversList( event.target.checked)}} checked={showDriversList}/>}/>
                            <FormControlLabel label="Trips List"
                                control={<Switch color="primary" size="small" onChange={(event)=>{setShowTripsList(event.target.checked) }} checked={showTripsList}/>}/>   
                            <FormControlLabel label="Picks"
                                control={<Switch color="primary" size="small" onChange={(event)=>{setShowPicks(event.target.checked) }} checked={showPicks}/>}/>
                            <FormControlLabel  label="Deliveries"
                                control={<Switch color="primary" size="small" onChange={(event)=>{setShowDelivery(event.target.checked) }}   checked={showDelivery}/>}/>
                            <FormControlLabel label="Drivers"
                                control={<Switch color="primary" size="small" onChange={(event)=>{setShowDrivers(event.target.checked) }}   checked={showDrivers}/> }/>
                        </FormGroup>
                        <Tooltip title="Map Legend">
                            <IconButton   className="btnPrimary"  aria-label="Map Legend"  onClick={handlePopoverOpen}>
                                <NotListedLocationIcon />
                            </IconButton>
                        </Tooltip>
                        <Popover
                            open={open}
                            className="popoverDark"
                            anchorEl={anchorEI}
                            onClose={handlePopoverClose}
                            anchorOrigin={{ vertical: "bottom",  horizontal: "right",}}
                            transformOrigin={{ vertical: "top", horizontal: "right", }}
                        >
                            <div>
                                <img src={mapLegend}  style={{ width: "577px", display: "block", height: "auto",}} alt="Map Legend"/>
                            </div>
                        </Popover>
                    </div>
                </Grid>
            </Grid>
        </AppBar>

        {showDriversList && (
            <DriverList  driverList={drivers} updateDriver={updateDriverNumber} companyID={props.companyID} accessToken={accessToken}/>
        )}
        {showTripsList && (
            <TripList  boardID={boardID} tripList={trips} companyID={props.companyID} accessToken={accessToken}/>
        )}
        {renderDriverInfo()}
        <Grid  container  direction="row"  justifyContent="center"  alignItems="start"  spacing={0}  sm={12}>
            <Grid item xs={12} md={8}>
                {currentDriver === "" && allMapPoints.length > 0 ? (
                <AllMap
                    boardID={boardID}
                    tripList={trips}
                    companyID={props.companyID}
                    mapPoints={allMapPoints}
                    accessToken={accessToken}
                    handlerDriverPinClick={handlerDriverPinClick}
                    hoverHandler={handleDriverMarkerHover}
                    IsShowDrivers={showDrivers}
                    IsShowPickup={showPicks}
                    IsShowDelivery={showDelivery}                
                />) : (<></>)}     

                {currentDriver !== "" ? (
                <OneDriverMap
                    boardID={boardID}
                    tripList={trips}
                    companyID={props.companyID}
                    tripPinsList={tripMapPoints}
                    accessToken={accessToken}
                    currentDriver={currentDriver}
                    hoverHandler={handleDriverMarkerHover}
                    IsShowPickup={showPicks}
                    IsShowDelivery={showDelivery}
                    IsShowBreadcrumbPoints={showBreadcrumbPoints}
                    startTime = {breadcrumbsStartTime}
                    endTime = {breadcrumbsEndTime}
                />) : (<></>)}     
            </Grid>
            <Grid item xs={12} md={4}>
                <Grid container direction="column" item sm={12} style={{ padding: "10px" }}>
                    <Grid item>
                        <Box display="flex"  border={0} className="darkForm" style={{ flexwrap: "nowrap", marginBottom: "10px" }}  sx={{ height: "auto" }}>
                            <TextField  id="msgDrv"  label="Driver#"  variant="outlined"   size="small"   style={{ marginRight: "10px", width: "20%" }}
                                value={sendMsgDriver}  onChange={(event)=>{setSendMsgDriver(event.target.value)}}  inputProps={{ maxLength: 3 }}/>
                            <TextField  id="msgText" label="Send Message"  value={messageToSend} variant="outlined"  size="small"  style={{ width: "70%" }}   onChange={(event)=>{ setMessageToSend(event.target.value ) }}/>                 
                            <div style={{ marginLeft: "5px" }}>
                                <Tooltip title="Send">
                                    <IconButton  className="btnPrimary"  aria-label="Send"  onClick={handleSendDriverMsgClick}><SendIcon /></IconButton>
                                </Tooltip>
                            </div>
                        </Box>
                    </Grid>
                    <Grid item>
                        {currentDriver === "" ? (
                            <MessageList  updateDriver={updateDriverNumber}  companyID={props.companyID} accessToken={accessToken}/>
                        ) : (<></>)}
                        {currentDriver !== "" ? (
                            <DriverMessageList  currentDriver={currentDriver} companyID={props.companyID}  accessToken={accessToken}/>
                        ) : (<></>)}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <footer><Copyright  textColor='white'/></footer>
    </div>
    )
}

