import React, { useRef, useState, useEffect } from "react";
import { format, parseISO, isValid } from "date-fns";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.dark.css";
import DataGrid, {
  Column,
  Selection,
  FilterRow,
  Paging,
  Export,
  ColumnChooser,
  Pager,
  SearchPanel,
  GroupPanel,
  Grouping,
  GroupItem,
  SortByGroupSummaryInfo,
  Summary,
  MasterDetail,
  StateStoring,
  ColumnFixing,
  HeaderFilter,
  FilterPanel,
  FilterBuilderPopup,
  Scrolling,
} from "devextreme-react/data-grid";

import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import NoticeEmitter from "../NoticeUtil.js";
import httpRequest from "../HttpService";
import Typography from "@material-ui/core/Typography";

import "./search.css";
import MasterDetailView from "./MaterDetailView";

const MemoizedTagIcons = React.memo(({ cellData }) => {
  let tagArray = [];
  
  if (cellData.value) {
    try {
      tagArray = JSON.parse(cellData.value);
    } catch (error) {
      return <span>Error parsing tags</span>;
    }
  }

  if (!Array.isArray(tagArray) || tagArray.length === 0) {
    return <span>No tags</span>;
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
      {tagArray.map((tag, index) => (
        tag.icon_url && (
          <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
            <img 
              src={tag.icon_url} 
              alt={tag.textKey || `Tag ${index + 1}`} 
              title={`${tag.textKey}${tag.variableDisplayValue ? ': ' + tag.variableDisplayValue : ''}`}
              style={{ width: '24px', height: '24px' }}
              onError={(e) => {
                e.target.style.display = 'none';
              }}
            />
          </div>
        )
      ))}
    </div>
  );
});

const GridContainer = React.memo(function GridContainer(props) {
  return (
    <div style={{ 
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      willChange: 'transform'
    }}>
      <DataGrid
        ref={props.gridRef}
        dataSource={props.dataSource}
        showBorders={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnResizingMode="widget"
        keyExpr="id"
        onSelectionChanged={props.onSelectionChanged}
        onExporting={props.onExporting}
        onRowPrepared={props.onRowPrepared}
      >
        {props.children}
      </DataGrid>
    </div>
  );
});

export default function DEDataGrid(props) {
  const [trips, setTrips] = useState([]);
  const orderDataTable = useRef(null);

  const useStyles = makeStyles({
    root: {
      width: '100%'
    },
    input: {
      borderBottom: 'none'
    },
    finishedRow: {
      backgroundColor: "#485054", 
      borderRadius: '6px', 
      color: '#fff',
      fontSize: '13px',
      fontWeight: '600',
      textAlign: 'center',
      padding: '0 6px',
    },
    evenRow: {
      backgroundColor: "#010C11", 
      borderRadius: '6px', 
      color: '#fff',
      fontSize: '13px',
      fontWeight: '600',
      textAlign: 'center',
      padding: '0 6px',
    },
    redRow: {
      backgroundColor: '#DE3562', 
      borderRadius: '6px', 
      color: '#fff',
      fontSize: '13px',
      fontWeight: '600',
      textAlign: 'center',
      padding: '0 6px',
    },
    yellowRow: {
      backgroundColor: '#C24D00', 
      borderRadius: '6px', 
      color: '#fff',
      fontSize: '13px',
      fontWeight: '600',
      textAlign: 'center',
      padding: '0 6px',
    },
    greenRow: {
      backgroundColor: "#007E82", 
      borderRadius: '6px', 
      color: '#fff',
      fontSize: '13px',
      fontWeight: '600',
      textAlign: 'center',
      padding: '0 6px',
    },
    blueRow: {
      backgroundColor: "#4331A4", 
      borderRadius: '6px', 
      color: '#fff',
      fontSize: '13px',
      fontWeight: '600',
      textAlign: 'center',
      padding: '0 6px',
    }
  });

  const classes = useStyles();

  useEffect(() => {
    const listener = (data) => {
      try {
        if(orderDataTable?.current?.instance) {
          orderDataTable.current.instance.clearSelection();        
        }
      } catch (e) {
        console.log(e);
      }
    };
  
    NoticeEmitter.addListener("resetTable", listener);
    
    return () => {
      NoticeEmitter.removeListener("resetTable", listener);
    };
  }, []);

  const allowedPageSizes = [30, 50, 100];

  useEffect(() => {
    // Reset selection state whenever trips data changes
    if (orderDataTable?.current?.instance) {
      orderDataTable.current.instance.clearSelection();
      orderDataTable.current.instance.clearFilter();  // Also clear any filters
      orderDataTable.current.instance.state(null);    // Reset grid state
    }
  }, [props.trips]);  // Only run when trips data changes

  useEffect(() => {
      if (props.stateReset) {
        orderDataTable.current.instance.state(null);   
        props.setStateReset(false);
      }
  }, [props.stateReset]);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    try {
      const date = parseISO(dateString);
      if (!isValid(date)) return '';
      return format(date, "HH:mm MMM dd yyyy");
    } catch (error) {
      console.warn("Date formatting error:", error);
      return '';
    }
  };

  useEffect(() => {
    const now = new Date();
    
    const processedTrips = props.trips.map((trip, index) => {
      const dueDate = trip.dtDCT_UTC ? parseISO(trip.dtDCT_UTC) : null;
      const diff = dueDate && isValid(dueDate) ? dueDate.getTime() - now.getTime() : null;
      
      return {
        ...trip,
        id: index,
        diff,
        StatusCode: trip.Status,
        SER_TIME: trip.SER_TIME?.toString(),
        RATE: trip.RATE?.toString(),
        dtDCT_UTC: formatDate(trip.dtDCT_UTC),
        dtCallIn_UTC: formatDate(trip.dtCallIn_UTC),
        dtDelivered_UTC: formatDate(trip.dtDelivered_UTC),
      };
    });
    
    setTrips(processedTrips);
  }, [props.trips]);

  const renderJobID = React.useCallback((params) => {   
    return (
      <a
        style={{ color: "#13ACB1" }}
        onClick={() => {
          let inxURL = params.data.isLattice ? `/latticeJobDetail/${params.value}` : `/jobdetail/${params.value}`;
          window.open(inxURL, "_blank");
        }}
      >
        {params.value}{" "}
      </a>
    );
  }, []);

  const renderDueBy = React.useCallback((params) => {
    if (!params.value) return "";
    return (
      <Typography
        className={
          params.data["diff"] < 0 && params.data["StatusCode"] < 8
            ? classes.redRow
            : params.data["diff"] >= 0 &&
              params.data["diff"] <= 10 * 60 * 1000 &&
              params.data["StatusCode"] < 8
            ? classes.yellowRow
            : params.data["blueStar"] && params.data["StatusCode"] < 8
            ? classes.blueRow
            : params.data["diff"] > 10 * 60 * 1000 &&
              params.data["StatusCode"] < 8
            ? classes.greenRow
            : classes.finishedRow
        }
      >
        {params.value}
      </Typography>
    );
  }, [classes]);

  const renderDate = React.useCallback((params) => {
    return params.value || "";
  }, []);

  const renderInstructions = React.useCallback((params) => {
    return (
      <TextField
        fullWidth      
        multiline 
        placeholder="" 
        style={{ width: '100%' }} 
        defaultValue={params.value} 
        InputProps={{ 
          readOnly: true,
          disableUnderline: true,
          style: {
            cursor: 'default',
            '&:hover': {
              cursor: 'default'
            }
          }
        }}
      />    
    );
  }, []);

  const onSelectionChanged = React.useCallback(({ selectedRowsData }) => {
    props.setSelection(selectedRowsData);
  }, [props.setSelection]);

  const onExporting = React.useCallback((e) => {
    if (e.format === "xlsx") {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Companies");
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "inx-orders.xlsx"
          );
        });
      });
      e.cancel = true;
    } else if (e.format === "csv") {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Employees");
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
      }).then(function () {
        workbook.csv.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "inx-orders.csv"
          );
        });
      });
    }
  }, []);

  const exportFormats = ["xlsx", "csv"];

  const filterBuilderPopupPosition = {
    of: window,
    at: "top",
    my: "top",
    offset: { y: 10 },
  };

  return (
    <GridContainer
      gridRef={orderDataTable}
      dataSource={trips}
      onSelectionChanged={onSelectionChanged}
      onExporting={onExporting}
      onRowPrepared={(e) => {
        if (e.data &&(e.data.Status === "Verified" || e.data.Status === "Invoiced" || e.data.Status === "Delivered"))
          e.rowElement.style.backgroundColor = "#0D191E";
         else if (e.data && e.rowIndex % 2 === 0)
          e.rowElement.style.backgroundColor = "#0D191E";
        else if (e.data && e.rowIndex % 2 !== 0)
          e.rowElement.style.backgroundColor = "#010C11"; 
      }}
    >
      <FilterPanel visible={true} />
      <FilterBuilderPopup position={filterBuilderPopupPosition} />
      <HeaderFilter visible={true} />
      <GroupPanel visible={true} />
      <Grouping autoExpandAll={false} />
      <SearchPanel visible={true} width={240} placeholder="Search..." />
      <Selection
        mode="multiple"
        selectAllMode="page"
        showCheckBoxesMode="always"
      />
      <FilterRow visible={true} />
      <Paging defaultPageSize={50} />
      <Pager
        visible={true}
        showInfo={true}
        showPageSizeSelector={true}
        allowedPageSizes={allowedPageSizes}
        infoText="Page {0} of {1} ({2} items)"
        displayMode="adaptive"        
      />  
      <Column dataField="jobID" caption="JobID" width={100} cellRender={renderJobID} />
      <Column dataField="ACCOUNT" caption="Account" width={90} />     
      <Column dataField="statusString" caption="Status" width={150} />
      <Column dataField="locationString" caption="Location" width={100} />
      <Column dataField="SER_AREA" caption="Ser. Area" width={80} />
      <Column dataField="SER_TIME" caption="Ser. Time" width={80}/>     
      <Column dataField="rate" caption="Rate" width={70} />  
      <Column dataField="CALLER" caption="Caller" width={100} />       
      <Column dataField="CUST_REF" caption="Reference" width={130}/> 
      <Column dataField="PROOF_DEL" caption="Deli.Proof" width={120}  visible={!props.defaultChecked}/>
      <Column dataField="dtDelivered_UTC" caption="Deli. By" width={150} cellRender={renderDate} visible={!props.defaultChecked}/>   
      <Column dataField="pickDriver" caption="1st Drv." width={80} />
      <Column dataField="last_driv" caption="Last Drv." width={80} />  
      <Column dataField="dtCallIn_UTC" caption="Call Time" width={150} cellRender={renderDate}/>     
      <Column dataField="dtDCT_UTC" caption="Due By" width={150} cellRender={renderDueBy}/>
      <Column dataField="sCompanyName" caption="From" width={200} />     
      <Column dataField="sAddress" caption="Sh. Address" width={120}/>    
      <Column dataField="sPostal" caption="Sh. Postal" width={80}/>  
      <Column dataField="sQuad" caption="sh. Quad" width={80}/>
      <Column dataField="sCity" caption="sh. City" width={80} /> 
      <Column dataField="sContact" caption="Sh. Contact" width={100} visible={!props.defaultChecked && !props.verficationChecked}/>
      <Column dataField="sPhone" caption="sh. Phone" width={120} visible={!props.defaultChecked && !props.verficationChecked}/>
      <Column dataField="sLat" caption="sh. Lat" width={100} visible={!props.defaultChecked && !props.verficationChecked}/>
      <Column dataField="sLong" caption="sh. Long" width={100} visible={!props.defaultChecked && !props.verficationChecked}/>
      <Column dataField="cCompanyName" caption="To" width={200}/>
      <Column dataField="cAddress" caption="Co. Address" width={120}/>
      <Column dataField="cQuad" caption="Co. Quad" width={80}/>
      <Column dataField="cCity" caption="Co. City" width={80}/>
      <Column dataField="cPostal" caption="Co. Postal" width={80}/>  
      <Column dataField="cContact" caption="Co. Contact" width={100} visible={!props.defaultChecked && !props.verficationChecked}/>
      <Column dataField="cPhone" caption="Co. Phone" width={120} visible={!props.defaultChecked && !props.verficationChecked}/>
      <Column dataField="cLat" caption="Co. Lat" width={100} visible={!props.defaultChecked && !props.verficationChecked}/>
      <Column dataField="cLong" caption="Co. Long" width={100} visible={!props.defaultChecked && !props.verficationChecked}/>
      <Column dataField="creator" caption="Creator" width={100} visible={!props.defaultChecked && !props.verficationChecked}/>
      <Column dataField="DESC" caption="PackageType" width={100} visible={!props.defaultChecked && !props.verficationChecked}/>  
      <Column dataField="PIECES" caption="Pieces" width={75} visible={props.defaultChecked}/>    
      <Column dataField="WEIGHT" caption="Weight" width={80} visible={props.defaultChecked}/>
      <Column dataField="DEL_INST" caption="Instructions" width={200} cellRender={renderInstructions} visible={props.defaultChecked} />   
      <Column 
        dataField="tagIcons" 
        caption="Tags" 
        width={120}
        cellRender={(cellData) => <MemoizedTagIcons cellData={cellData} />}
        allowFiltering={false}
        allowSorting={false}
        visible={true}
      />
      <Column 
        dataField="BOLURL" 
        caption="BOL" 
        width={70} 
        alignment="center"
        cellRender={(cellData) => {
          if (!cellData.value) return null;
          return (
            <a 
              href={cellData.value}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                window.open(cellData.value, '_blank');
              }}
              style={{ color: "#13ACB1" }}
            >
              <i className="dx-icon-download" />
            </a>
          );
        }}
      />
      <ColumnChooser enabled={true} mode="select" />
      <StateStoring enabled={true} type="localStorage" storageKey="searchStorage" /> 
      <Summary>
        <GroupItem
          column="jobID"
          summaryType="count"
          displayFormat="{0} orders"
        />
        <GroupItem
          column="RATE"
          summaryType="avg"
          valueFormat="decimal"
          displayFormat="Avg Rate:${0}"
          showInGroupFooter={true}
          alignByColumn={false}
        />
        <GroupItem
          column="RATE"
          summaryType="sum"
          valueFormat="##0.00"
          displayFormat="Sum Rate:${0}"
          alignByColumn={true}
        />
      </Summary>
      <SortByGroupSummaryInfo summaryItem="jobID" />
      <MasterDetail enabled={true} component={MasterDetailView} />
      <Export
        enabled={true}
        formats={exportFormats}
        allowExportSelectedData={true}
      />
    </GridContainer>
  );
}