import React, { useState, useEffect } from "react";
import httpRequest from "../../HttpService";
import "devextreme/dist/css/dx.dark.css";
import DataGrid, {
  Column,
  Selection,
  FilterRow,
  Export,
  ColumnChooser,
  SearchPanel,
} from "devextreme-react/data-grid";

const TripHistoryList = (props) => {
  const [tripHistoryList, setTripHistoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp); // This correctly parses the UTC timestamp
    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: 'UTC'  // This tells it to stay in UTC rather than convert to local
    });
  };

  const loadMoreData = async (e) => {
    const rowCount = e.component.getVisibleRows().length;
    
    if (e.component.pageIndex() * e.component.pageSize() >= rowCount - 20 && !isLoading) {
      setIsLoading(true);
      
      try {
        const response = await httpRequest.getTripHistory(props.accessToken, props.jobId);
        if (response.data.length !== 0) {
          const newHistory = response.data.map((value, index) => ({
            ...value,
            originalTimestamp: value.timestamp,
            timestamp: formatDate(value.timestamp),
            id: tripHistoryList.length + index
          }));
          
          const sortedNewHistory = newHistory.sort((a, b) => 
            new Date(b.originalTimestamp) - new Date(a.originalTimestamp)
          );
          
          setTripHistoryList(prevList => [...prevList, ...sortedNewHistory]);
        }
      } catch (error) {
        console.error("Error loading more data:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      try {
        const body = await httpRequest.getTripHistory(props.accessToken, props.jobId);
        if (body.data.length !== 0) {
          const historyList = body.data.map((value, index) => {
            return {
              ...value,
              originalTimestamp: value.timestamp,
              timestamp: formatDate(value.timestamp),
              id: index
            };
          });
          
          // Sort by original timestamp
          historyList.sort((a, b) => 
            new Date(b.originalTimestamp) - new Date(a.originalTimestamp)
          );
          
          setTripHistoryList(historyList);
          setTotalCount(historyList.length);
        }
      } catch (error) {
        console.error("Error fetching initial data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, [props.accessToken, props.jobId]);

  const renderDrNum = (params) => {
    return <a style={{ color: "#13ACB1" }}>{params.value}</a>;
  };

  const WrappingCell = (cellData) => {
    return (
      <div style={{ 
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        lineHeight: '1.2'
      }}>
        {cellData.value}
      </div>
    );
  };

  return (
    <div className="darkDataGrid" style={{ 
      width: "100%", 
      height: "100%",
      display: "flex",
      flexDirection: "column"
    }}>
      <DataGrid
        dataSource={tripHistoryList}
        showBorders={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        keyExpr="id"
        rowAlternationEnabled={true}
        onScroll={loadMoreData}
        scrolling={{ mode: 'infinite', rowRenderingMode: 'virtual' }}
        wordWrapEnabled={true}
        height="100%"
      >
        <SearchPanel visible={true} width={240} placeholder="Search..." />
        <Column dataField="timestamp" caption="Date/Time" width={200} />
        <Column 
          dataField="descr" 
          caption="History Description" 
          width={280}
          cellRender={WrappingCell}
        />
        <Column 
          dataField="messageText" 
          caption="message"
          cellRender={WrappingCell}
        />
        <Column dataField="drNum" caption="Creator" cellRender={renderDrNum} width={80} />
      </DataGrid>
    </div>
  );
};

export default TripHistoryList;