import React, { useRef, useState, useEffect } from "react";
import { format, parseISO } from "date-fns";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.dark.css";
import DataGrid, {
  Column,
  Selection,
  FilterRow,
  Paging,
  Export,
  ColumnChooser,
  Pager,
  SearchPanel,
  GroupPanel,
  Grouping,
  GroupItem,
  SortByGroupSummaryInfo,
  Summary,
  StateStoring,
  ColumnFixing,
  HeaderFilter,
  FilterPanel,
  FilterBuilderPopup,
  Scrolling,
  Sorting,
} from "devextreme-react/data-grid";

import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import NoticeEmitter from "../NoticeUtil.js";
import httpRequest from "../HttpService.js";

import "./search.css";

const GridContainer = React.memo(function GridContainer(props) {
  return (
    <div style={{ 
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      willChange: 'transform'
    }}>
      <DataGrid
        ref={props.gridRef}
        dataSource={props.dataSource}
        showBorders={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnResizingMode="widget"
        keyExpr="id"
        onSelectionChanged={props.onSelectionChanged}
        onExporting={props.onExporting}
        onRowPrepared={props.onRowPrepared}
        className={props.className}
      >
        {props.children}
      </DataGrid>
    </div>
  );
});

const MemoizedTagIcons = React.memo(({ cellData }) => {
  let tagArray = [];
  
  if (cellData.value) {
    try {
      tagArray = JSON.parse(cellData.value);
    } catch (error) {
      return <span>Error parsing tags</span>;
    }
  }

  if (!Array.isArray(tagArray) || tagArray.length === 0) {
    return <span>No tags</span>;
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px', alignItems: 'center', height: '100%' }}>
      {tagArray.map((tag, index) => (
        tag.icon_url && (
          <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
            <img 
              src={tag.icon_url} 
              alt={tag.textKey || `Tag ${index + 1}`} 
              title={`${tag.textKey}${tag.variableDisplayValue ? ': ' + tag.variableDisplayValue : ''}`}
              style={{ width: '24px', height: '24px' }}
              onError={(e) => {
                e.target.style.display = 'none';
              }}
            />
          </div>
        )
      ))}
    </div>
  );
});

const MemoizedTextField = React.memo(({ content, className }) => (
  <TextField
    fullWidth
    multiline
    placeholder=""
    style={{ width: '100%' }}
    defaultValue={content}
    InputProps={{ 
      readOnly: true,
      disableUnderline: true,
      style: {
        cursor: 'default',
        '&:hover': {
          cursor: 'default'
        }
      }
    }}
    className={className}
  />
));

export default function DEDataGrid(props) {
  const [trips, setTrips] = useState([]);
  const orderDataTable = useRef(null);

  const useStyles = makeStyles({
    root: {
      width: '100%',
      '& .dx-datagrid-content .dx-datagrid-table .dx-row > td': {
        verticalAlign: 'middle'
      }
    },
    inputWrapper: {
      '& .MuiInputBase-root': {
        height: '80',
        fontSize: '0.9rem',
        lineHeight: '0.9'
      }
    },
    cell: {
      display: 'flex',
      alignItems: 'center',
      height: '100%'
    },
    noUnderline: {
      '&:before': {
        borderBottom: 'none'
      },
      '&:after': {
        borderBottom: 'none'
      }
    }
  });

  const classes = useStyles();

  useEffect(() => {
    const listener = (data) => {
      try {
        if(orderDataTable?.current?.instance) {
          orderDataTable.current.instance.clearSelection();        
        }
      } catch (e) {
        console.log(e);
      }
    };

    NoticeEmitter.addListener("resetTable", listener);
    
    return () => {
      NoticeEmitter.removeListener("resetTable", listener);
    };
  }, []);

  const allowedPageSizes = [30, 50, 100];

  useEffect(() => {
    if (props.stateReset) {
      orderDataTable.current.instance.state(null);   
      props.setStateReset(false);
    }
  }, [props.stateReset]);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    try {
      const date = parseISO(dateString);
      if (isNaN(date.getTime())) return '';
      return format(date, "HH:mm MMM. d");
    } catch (error) {
      console.error("Date parsing error:", error);
      return '';
    }
  };
  
  useEffect(() => {
    const processedTrips = props.trips.map((trip, index) => ({
      ...trip,
      id: index,
      SER_TIME: trip.SER_TIME?.toString() || '',
      RATE: trip.RATE?.toString() || '',
      dtDCT_UTC: formatDate(trip.dtDCT_UTC),
      dtCallIn_UTC: formatDate(trip.dtCallIn_UTC),
      dtDelivered_UTC: formatDate(trip.dtDelivered_UTC)
    }));
    
    setTrips(processedTrips);
  }, [props.trips]);

  useEffect(() => {
    // Reset selection state whenever trips data changes
    if (orderDataTable?.current?.instance) {
      orderDataTable.current.instance.clearSelection();
      orderDataTable.current.instance.clearFilter();  // Also clear any filters
      orderDataTable.current.instance.state(null);    // Reset grid state
    }
  }, [props.trips]);  // Only run when trips data changes

  const renderJobID = React.useCallback((params) => {   
    return (
      <div className={classes.cell}>
        <a
          style={{ color: "#13ACB1", cursor: 'pointer' }}
          onClick={() => {
            let inxURL = params.data.isLattice ? `/latticeJobDetail/${params.value}` : `/jobdetail/${params.value}`;
            window.open(inxURL, "_blank");
          }}
        >
          {params.value}{" "}
        </a>
      </div>
    );
  }, [classes]);

  const renderMultiline = React.useCallback((params, fieldConfig) => {
    const content = fieldConfig.map(field => {
      if (typeof field === 'string') {
        return params.data[field];
      } else if (typeof field === 'object' && field.combineFields) {
        return field.combineFields.map(f => params.data[f]).filter(Boolean).join(', ');
      }
      return '';
    }).filter(Boolean).join('\n');
  
    return <MemoizedTextField content={content} className={classes.inputWrapper} />;
  }, [classes]);

  const renderAddress = React.useCallback((params, type) => {
    const prefix = type === 'shipper' ? 's' : 'c';
    let fieldConfig;
  
    if (type === 'shipper') {
      fieldConfig = [
        `${prefix}CompanyName`,
        `shipperAddressLine1`,
        `shipperAddressLine2`,
      ];
    } else {
      fieldConfig = [
        `${prefix}CompanyName`,
        `consigneeAddressLine1`,
        `consigneeAddressLine2`,
        { combineFields: [`${prefix}Contact`, `${prefix}Phone`] },
      ];
    }
  
    return renderMultiline(params, fieldConfig);
  }, [renderMultiline]);

  const renderStatusLocation = React.useCallback((params) => {
    return renderMultiline(params, ['statusString', 'locationString']);
  }, [renderMultiline]);

  const renderFromCityQuad = React.useCallback((params) => {
    const city = params.data.sCity || '';
    const quad = params.data.sQuad || '';
    return (
      <div>
        {city} {quad}
      </div>
    );
  }, []);
  
  const renderToCityQuad = React.useCallback((params) => {
    const city = params.data.cCity || '';
    const quad = params.data.cQuad || '';
    return (
      <div>
        {city} {quad}
      </div>
    );
  }, []);

  const renderServiceAndDueBy = React.useCallback((params) => {
    const serviceInfo = `${params.data.SER_AREA} - ${params.data.SER_TIME}`;
    const dueBy = params.data.dtDCT_UTC;
    
    return (
      <div>
        <div>{serviceInfo}</div>
        <div style={{ fontWeight: 'bold' }}>{dueBy}</div>
      </div>
    );
  }, []);

  const renderDeliProofAndBy = React.useCallback((params) => {
    const deliProof = params.data.PROOF_DEL || 'N/A';
    const deliBy = params.data.dtDelivered_UTC || 'Not Delivered';
    
    return (
      <div>
        <div>POD: {deliProof}</div>
        <div>{deliBy}</div>
      </div>
    );
  }, []);

  const renderFirstDriver = React.useCallback((params) => {
    const firstDriver = params.data.pickDriver || 'N/A';
    return <div>{firstDriver}</div>;
  }, []);

  const renderLastDriver = React.useCallback((params) => {
    const lastDriver = params.data.last_driv || 'N/A';
    return <div>{lastDriver}</div>;
  }, []);

  const renderCallerCreatorCallTime = React.useCallback((params) => {
    const caller = params.data.CALLER || '';
    const creator = params.data.creator || '';
    const contact = params.data.sContact || ''; 
    const phone = params.data.sPhone || ''; 
    const callTime = params.data.dtCallIn_UTC || '';
    
    return (
      <div>
        <div>{caller}{creator ? ` (${creator})` : ''}</div>
        <div>{contact} {phone}</div>
        <div>{callTime}</div>
      </div>
    );
  }, []);

  const renderAccount = React.useCallback((params) => {
    const accountName = params.data.ShipperName || '';
    const accountNumber = params.data.ACCOUNT || '';
    
    return (
      <div>
        <div>{accountName}</div>
        <div>{accountNumber}</div>
      </div>
    );
  }, []);

  const onSelectionChanged = React.useCallback(({ selectedRowsData }) => {
    props.setSelection(selectedRowsData);
  }, [props.setSelection]);

  const onExporting = React.useCallback((e) => {
    if (e.format === "xlsx") {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Companies");
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "inx-orders.xlsx"
          );
        });
      });
      e.cancel = true;
    } else if (e.format === "csv") {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Employees");
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
      }).then(function () {
        workbook.csv.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "inx-orders.csv"
          );
        });
      });
    }
  }, []);

  const exportFormats = ["xlsx", "csv"];

  const filterBuilderPopupPosition = {
    of: window,
    at: "top",
    my: "top",
    offset: { y: 10 },
  };

  const onRowPrepared = React.useCallback((e) => {
    if (e.data &&(e.data.Status === "Verified" || e.data.Status === "Invoiced" || e.data.Status === "Delivered"))
      e.rowElement.style.backgroundColor = "#0D191E";
     else if (e.data && e.rowIndex % 2 === 0)
      e.rowElement.style.backgroundColor = "#0D191E";
    else if (e.data && e.rowIndex % 2 !== 0)
      e.rowElement.style.backgroundColor = "#010C11"; 
  }, []);

  const renderBOLURL = React.useCallback((cellData) => {
    if (!cellData.value) return null;
    return (
      <a 
        href={cellData.value}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          window.open(cellData.value, '_blank');
        }}
        style={{ color: "#13ACB1" }}
      >
        <i className="dx-icon-download" />
      </a>
    );
  }, []);

  return (
    <GridContainer
      gridRef={orderDataTable}
      dataSource={trips}
      onSelectionChanged={onSelectionChanged}
      onExporting={onExporting}
      onRowPrepared={onRowPrepared}
      className={classes.root}
    >
      <Sorting>
        <Column dataField="dtCallIn_UTC" sortOrder="desc" />
      </Sorting>
      <FilterPanel visible={true} />
      <FilterBuilderPopup position={filterBuilderPopupPosition} />
      <HeaderFilter visible={true} />
      <GroupPanel visible={true} />
      <Grouping autoExpandAll={false} />
      <SearchPanel visible={true} width={240} placeholder="Search..." />
      <Selection
        mode="multiple"
        selectAllMode="page"
        showCheckBoxesMode="always"
      />
      <FilterRow visible={true} />
      <Paging defaultPageSize={50} />
      <Pager
        visible={true}
        showInfo={true}
        showPageSizeSelector={true}
        allowedPageSizes={allowedPageSizes}
        infoText="Page {0} of {1} ({2} items)"
        displayMode="adaptive"        
      />  
      <Column dataField="jobID" caption="JobID" width={100} cellRender={renderJobID} />
      <Column 
        dataField="ACCOUNT"
        caption="Account"
        width={150} 
        cellRender={renderAccount}
      />      
      <Column 
        className="orangeText"
        dataField="sCompanyName" 
        caption="From" 
        width={280}
        cellRender={(params) => renderAddress(params, 'shipper')}
      />
      <Column 
        className="orangeText"
        dataField="cCompanyName" 
        caption="To" 
        width={280}
        cellRender={(params) => renderAddress(params, 'consignee')}
      />
      <Column dataField="sCity" caption="From City" width={80} />
      <Column dataField="sQuad" caption="From Quad" width={80} />
      <Column dataField="cCity" caption="To City" width={80} />
      <Column dataField="cQuad" caption="To Quad" width={80} />
      <Column 
        dataField="statusString" 
        caption="Status & Location" 
        width={150}
        cellRender={renderStatusLocation}
      />
      <Column 
        dataField="CALLER"
        caption="Caller & Call Time"
        width={180}
        cellRender={renderCallerCreatorCallTime}
      />
      <Column 
        dataField="SER_AREA"
        caption="Service & Due By"width={150}
        cellRender={renderServiceAndDueBy}
      />
      <Column 
        dataField="pickDriver"
        caption="First Driver"
        width={80}
        cellRender={renderFirstDriver}
      />
      <Column 
        dataField="last_driv"
        caption="Last Driver"
        width={80}
        cellRender={renderLastDriver}
      />
      <Column dataField="rate" caption="Rate" width={70} />  
      <Column dataField="CUST_REF" caption="Reference" width={130}/> 
      <Column 
        dataField="PROOF_DEL"
        caption="Delivery Info"
        width={180}
        cellRender={renderDeliProofAndBy}
        visible={!props.defaultChecked}
      />
      <Column 
        dataField="DESC" 
        caption="Package Type" 
        width={100} 
        visible={true}
      />  
      <Column dataField="PIECES" caption="Pieces" width={75} visible={props.defaultChecked}/>    
      <Column dataField="WEIGHT" caption="Weight" width={80} visible={props.defaultChecked}/>
      <Column 
        dataField="DEL_INST" 
        caption="Instructions" 
        width={200} 
        cellRender={(params) => renderMultiline(params, ['DEL_INST'])} 
        visible={props.defaultChecked} 
      />   
      <Column 
        dataField="tagIcons" 
        caption="Tags" 
        width={120}
        cellRender={(cellData) => <MemoizedTagIcons cellData={cellData} />}
        calculateFilterExpression={(filterValue, selectedFilterOperation) => {
          try {
            const tags = JSON.parse(filterValue || '[]');
            return tags.some(tag => tag.textKey.toLowerCase().includes(filterValue.toLowerCase()));
          } catch {
            return ['tagIcons', 'contains', filterValue];
          }
        }}
        calculateSortValue={(data) => {
          try {
            const tags = JSON.parse(data.tagIcons || '[]');
            return tags.map(tag => tag.textKey).join(', ');
          } catch {
            return '';
          }
        }}
        visible={true}
      />
      <Column dataField="sPostal" caption="Sh. Postal" width={80}/>  
      <Column dataField="cPostal" caption="Co. Postal" width={80}/>  

      <Column 
        dataField="BOLURL" 
        caption="BOL" 
        width={70} 
        alignment="center"
        cellRender={renderBOLURL}
      />
      <ColumnChooser enabled={true} mode="select" />
      <StateStoring enabled={true} type="localStorage" storageKey="searchStorage" /> 
      <Summary>
        <GroupItem
          column="jobID"
          summaryType="count"
          displayFormat="{0} orders"
        />
        <GroupItem
          column="RATE"
          summaryType="avg"
          valueFormat="decimal"
          displayFormat="Avg Rate:${0}"
          showInGroupFooter={true}
          alignByColumn={false}
        />
        <GroupItem
          column="RATE"
          summaryType="sum"
          valueFormat="##0.00"
          displayFormat="Sum Rate:${0}"
          alignByColumn={true}
        />
      </Summary>
      <SortByGroupSummaryInfo summaryItem="jobID" />
      <Export
        enabled={true}
        formats={exportFormats}
        allowExportSelectedData={true}
      />
    </GridContainer>
  );
}