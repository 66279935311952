import React, { useState, useEffect } from 'react';
import { AppBar, Typography, Box } from "@material-ui/core";
import HttpRequest from "../HttpService";
import RouteIcon from "@mui/icons-material/Route";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import BoltIcon from "@mui/icons-material/Bolt";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PaidIcon from "@mui/icons-material/Paid";
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";
import RefreshTimer from './RefreshTimer';

const DashboardBar = props => {  
    const getInitialFilter = () => {
        const savedFilter = localStorage.getItem('dashboardActiveFilter');
        return savedFilter || 'ALL';
    };

    const [boards, setBoards] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [localServiceChecked, setLocalServiceChecked] = useState(props.serviceChecked);
    const [localDispatchChecked, setLocalDispatchChecked] = useState(props.dispatchChecked);
    const [localSalesChecked, setLocalSalesChecked] = useState(props.salesChecked);
    const [localDcChecked, setLocalDcChecked] = useState(props.dcChecked);
    const [activeFilter, setActiveFilter] = useState(getInitialFilter());
    const [tripStats, setTripStates] = useState(props.tripStats);

    const classes = {
        root: { backgroundColor: "#010C11" },
        appBar: { overflow: "auto" },
        itemBox: {
            borderWidth: "1px",
            padding: "10px",
            borderRadius: "6px",
            boxSizing: "border-box",
        },
        boxNumber: {
            fontSize: 18,
            textAlign: "right",
            wordWrap: "break-word",
        },
        boxCaption: {
            fontSize: 12,
            whiteSpace: "nowrap",
            paddingLeft: "5px",
            height: "24px",
        },
        tripAssignBoxCaption: {
            fontSize: 12,
            color: "black",
        },
        leftNotices: { fontSize: 14 },
        searchBar: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
        },
        invisible: { display: "none" },
        oddRow: { backgroundColor: "#EEE" },
        evenRow: { backgroundColor: "#FFF" },
    };

    const filterButtonStyle = {
        base: {
            padding: '8px 16px',
            margin: '0 8px',
            borderRadius: '4px',
            border: '2px solid',
            cursor: 'pointer',
            fontWeight: 'bold',
            transition: 'all 0.3s ease',
            backgroundColor: 'transparent',
            position: 'relative',
            overflow: 'hidden',
        },
        active: {
            backgroundColor: '#13ACB1',
            color: 'white',
        }
    };

    useEffect(() => {
        const fetchBoards = async () => {
            try {
                console.log('Fetching boards with access token:', props.accessToken);
                const response = await HttpRequest.getServiceQueBoards(props.accessToken);
                
                console.log('Full response:', JSON.stringify(response, null, 2));
                console.log('Response type:', typeof response);
                console.log('Response data:', response.data);
                console.log('Response status:', response.status);
    
                // Check if the response is an HTML page
                if (typeof response.data === 'string' && response.data.includes('<!doctype html>')) {
                    console.error('Received HTML instead of JSON. Possible authentication issue.');
                    return;
                }
    
                // Ensure response.data is an array
                const boardsData = Array.isArray(response.data) ? response.data : [];
                setBoards(boardsData);
            } catch (error) {
                console.error('Error details:', {
                    message: error.message,
                    response: error.response ? JSON.stringify(error.response, null, 2) : 'No response',
                    request: error.request ? JSON.stringify(error.request, null, 2) : 'No request'
                });
                setBoards([]);
            } finally {
                setIsLoading(false);
            }
        };
        fetchBoards();
    }, [props.accessToken]);

    useEffect(() => {
        setTripStates(props.tripStats) 
    }, [props.tripStats]);

    useEffect(() => {
        setLocalServiceChecked(props.serviceChecked);
    }, [props.serviceChecked]);
  
    useEffect(() => {
        setLocalDispatchChecked(props.dispatchChecked);
    }, [props.dispatchChecked]);

    useEffect(() => {
        setLocalSalesChecked(props.salesChecked);
    }, [props.salesChecked]);

    useEffect(() => {
        setLocalDcChecked(props.dcChecked);
    }, [props.dcChecked]);

    useEffect(() => {
        const savedFilter = getInitialFilter();
        handleFilterChange(savedFilter);
    }, []);
   
    const boxWidth = 'auto';  

    const renderTripStatusBox = (key, label, value, BoxIcon) => (
        key !== "companyID" && key !== "tripAssignStats" && key !== "ServiceAlerts" && 
        key !== "ExceptionActive" && key !== "DriversLoggedIn" 
            ? renderStatusBox(key, label, value, BoxIcon)     
            : <></>
    );

    const renderStatusBox = (key, label, value, BoxIcon) => (
        <Box
            style={classes.itemBox}
            minWidth={boxWidth}
            className={boxClass[key]}
            border={1} 
            sx={{ border: `1px solid ${boxColors[key]}` }}
        >
            <Box style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}>
                <div style={{ color: boxColors[key] }}>
                    <BoxIcon />
                </div>
                <div style={{ color: boxColors[key] }}>
                    <Typography color="inherit" style={classes.boxCaption}>
                        {label}
                    </Typography>
                </div>
            </Box>
            <Box>
                <Typography color="white" style={classes.boxNumber}>
                    {value}
                </Typography>
            </Box>
        </Box>         
    );

    const icons = {
        "CalledInToday": RouteIcon,
        "RushToday": RouteIcon,
        "LastHour": BoltIcon,
        "RushLastHour": BoltIcon,
        "PoddedBurnsToday": WatchLaterIcon,
        "BurnsLastHour": WatchLaterIcon,
        "Burning": WatchLaterIcon,
        "DeliveredToday": CheckCircleIcon,
        "DeliveredLastHour": CheckCircleIcon,
        "DCTinNextHour": CheckCircleIcon,
        "DCTRestOfDay": CheckCircleIcon,
        "DCTFutureDates": CheckCircleIcon,
        "DriversLoggedIn": PersonPinIcon,
        "tripAssignStats": AssignmentIndIcon,
        "ServiceAlerts": NotificationsIcon,
        "ExceptionActive": WarningIcon,
        "ExceptionActiveSale": PaidIcon,
        "ExceptionActiveDC": ModeOfTravelIcon
    };

    const titles = {
        "CalledInToday": "TRIPS Today",
        "RushToday": "Rush",
        "LastHour": "Trips Last Hr",
        "RushLastHour": "Rush Last Hr",
        "PoddedBurnsToday": "LATE",
        "BurnsLastHour": "Late Last Hr",
        "Burning": " Late Not Del",
        "DeliveredToday": " PODS",
        "DeliveredLastHour": "PODS Last Hr",
        "DCTinNextHour": "DCT Next Hr",
        "DCTRestOfDay": " DCT TODAY",
        "DCTFutureDates": "DCT FUTURE",
        "DriversLoggedIn": "DRIVERS",
        "tripAssignStats": "ASSIGN",
        "ServiceAlerts": "ALERTS",
        "ExceptionActive": "EXCEPTION",
        "ExceptionActiveSale": "SALES",
        "ExceptionActiveDC": " D/C"
    };

    const boxColors = {
        "CalledInToday": "#F78133",
        "RushToday": "#9585EC",
        "LastHour": "#F78133",
        "RushLastHour": "#9585EC",
        "PoddedBurnsToday": "#DE3562",
        "BurnsLastHour": "#DE3562",
        "Burning": "#DE3562",
        "DeliveredToday": " #13ACB1",
        "DeliveredLastHour": "#13ACB1",
        "DCTinNextHour": "#9585EC",
        "DCTRestOfDay": "#9585EC",
        "DCTFutureDates": "#9585EC",
        "DriversLoggedIn": "#13ACB1",
        "tripAssignStats": "#13ACB1",
        "ServiceAlerts": "#DE3562",
        "ExceptionActive": "#F78133",
        "ExceptionActiveSale": "#F78133",
        "ExceptionActiveDC": "#F78133"
    };

    const boxClass = {
        "CalledInToday": "statBox statBoxWarning",
        "RushToday": "statBox statBoxSecondary",
        "LastHour": "statBox statBoxWarning",
        "RushLastHour": "statBox statBoxSecondary",
        "PoddedBurnsToday": "statBox statBoxDanger",
        "BurnsLastHour": "statBox statBoxDanger",
        "Burning": "statBox statBoxDanger",
        "DeliveredToday": "statBox statBoxPrimary",
        "DeliveredLastHour": "statBox statBoxPrimary",
        "DCTinNextHour": "statBox statBoxSecondary",
        "DCTRestOfDay": "statBox statBoxSecondary",
        "DCTFutureDates": "statBox statBoxSecondary",
        "DriversLoggedIn": "statBox statBoxPrimary",
        "tripAssignStats": "statBox statBoxWarning",
        "ServiceAlerts": "statBox statBoxDanger",
        "ExceptionActive": "statBox statBoxWarning",
        "ExceptionActiveSale": "statBox statBoxWarning",
        "ExceptionActiveDC": "statBox statBoxWarning"
    };

    const handleFilterChange = (filterType) => {
        console.log('Filter changed to:', filterType);
        console.log('Current boards:', boards);
        
        setActiveFilter(filterType);
        localStorage.setItem('dashboardActiveFilter', filterType);
        
        if (filterType === 'ALL') {
            props.setServiceChecked(true);
            props.setDispatchChecked(true);
            return;
        }

        const board = boards.find(b => b.name === filterType);
        console.log('Found board:', board);
        
        if (board) {
            props.setServiceChecked(board.type === 'SERVICE');
            props.setDispatchChecked(board.type === 'DISPATCH');
        }
    };

    const renderFilterButton = (name) => {
        const board = boards.find(b => b.name === name);
        const buttonColor = board?.color || (name === 'ALL' ? '#13ACB1' : '#9585EC');
        const isActive = activeFilter === name;
        const buttonClass = `filter-button-${name.toLowerCase()}`;

        return (
            <button
                key={name}
                onClick={() => handleFilterChange(name)}
                className={!isActive ? buttonClass : ''}
                style={{
                    padding: '8px 16px',
                    margin: '0 8px',
                    borderRadius: '4px',
                    border: '2px solid',
                    borderColor: buttonColor,
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    color: isActive ? 'white' : buttonColor,
                    backgroundColor: isActive ? buttonColor : 'transparent'
                }}
            >
                {name}
            </button>
        );
    };

    return (
        <AppBar position="static" className="appBarDark" style={classes.appBar}>
            <style>
                {`
                    .filter-button-service:hover { background-color: #F7813340 !important; }
                    .filter-button-dispatch:hover { background-color: #9585EC40 !important; }
                    .filter-button-all:hover { background-color: #13ACB140 !important; }
                `}
            </style>
            <Box display="flex" flexWrap="nowrap" flexDirection="column" m={1}>      
                <Box
                    display="flex"
                    
                    border={0}
                    className="statsWrapper"
                    sx={{ height: "auto" }}
                >
                    {renderStatusBox("tripAssignStats", titles["tripAssignStats"], tripStats["tripAssignStats"], icons["tripAssignStats"])}
                    {renderStatusBox("ServiceAlerts", titles["ServiceAlerts"], tripStats["ServiceAlerts"], icons["ServiceAlerts"])}
                    {renderStatusBox("ExceptionActive", titles["ExceptionActive"], tripStats["ExceptionActive"], icons["ExceptionActive"])}
                    {Object.keys(tripStats).map((key) =>
                        renderTripStatusBox(key, titles[key], tripStats[key], icons[key])       
                    )}
                    {renderStatusBox("DriversLoggedIn", titles["DriversLoggedIn"], tripStats["DriversLoggedIn"], icons["DriversLoggedIn"])}           
                </Box>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    px={2}
                    py={1}
                >
                    <Box display="flex" alignItems="center">
                        <Typography 
                            style={{ 
                                color: 'white',
                                marginRight: '12px',
                                fontSize: '14px',
                                fontWeight: 'bold'
                            }}
                        >
                            SHOW:
                        </Typography>
                        {!isLoading && (
                            <>
                                {renderFilterButton("ALL")}
                                {boards.map(board => 
                                    renderFilterButton(board.name)
                                )}
                            </>
                        )}
                        {isLoading && (
                            <Typography style={{ color: 'white' }}>
                                Loading...
                            </Typography>
                        )}
                    </Box>
                    <RefreshTimer 
                        onRefresh={props.pollData} 
                        interval={60000}
                        isEnabled={props.autoRefreshEnabled}
                        onToggle={(enabled) => props.setAutoRefreshEnabled(enabled)}
                    />
                </Box>
            </Box>
        </AppBar>
    );
};

export default DashboardBar;