import axios from "axios";


//const base_url = "http://192.168.2.16:6677/";
//const base_url = "http://192.168.2.201:6677/";

// const base_url = "https://lattice-inx-stage.azurewebsites.net/"; //dev

const base_url = window.location.origin +'/'; //staging

// const base_url = "https://inxapi.azurewebsites.net/";

// let base_url = `${window.location.origin}/`;
// if (process.env && process.env.NODE_ENV === "development") {


const wd5_base_url = "https://webxapi.azurewebsites.net/";

const wd_axios = axios.create({
  headers: {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '-1'
  }
});

class HttpRequest { 
  
  get_newTripsHistory(accessToken, companyID) {
    const utc = (new Date(new Date().toUTCString()).getTime())/1000;
    return wd_axios.get(`${base_url}newTripHistory/${accessToken}/${companyID}/${utc}`);
  }

  get_autoDispatchHistory(accessToken, companyID) {
    const utc = (new Date(new Date().toUTCString()).getTime())/1000;
    return wd_axios.get(`${base_url}autoDispatchHistory/${accessToken}/${companyID}/${utc}`);
  }

  getAPIVersion = () => {
    return wd_axios.get(`${base_url}version`);
  };

  in_login = (body) => {
    return wd_axios.post(base_url + "inlogin", body);
  };

  generateVerifyCode = (body) => {
    return wd_axios.patch(base_url + "generateVerifyCode", body);
  };

  getTripAvailableTags = (accessToken,id) => {
    return wd_axios.get(`${base_url}tripAvailableTags/${accessToken}/${id}`);
  };

  getTripIsLattice = (accessToken, jobID) => {
    return wd_axios.get(`${base_url}tripIsLattice/${accessToken}/${jobID}`);
  };

  getStatusDD = (accessToken) => {
    return wd_axios.get(`${base_url}statusDD/${accessToken}`);
  };

  getLocationDD = (accessToken) => {   
    return wd_axios.get(`${base_url}locationDD/${accessToken}`);
  };

  getTripRating = (accessToken, id) => {   
    return wd_axios.get(`${base_url}tripRating/${accessToken}/${id}`);
  };

  doTripReRate = (accessToken, id) => {   
    return wd_axios.get(`${base_url}tripReRate/${accessToken}/${id}`);
  };


  getTripDrivers = (accessToken, id) => {    
    return wd_axios.get(`${base_url}tripDrivers/${accessToken}/${id}`);
  };

  getTripFunctions = (accessToken, id) => {    
    return wd_axios.get(`${base_url}tripFunctions/${accessToken}/${id}`);
  };

  getTripHistory = (accessToken, id) => {   
    return wd_axios.get(`${base_url}tripHistory/${accessToken}/${id}`);
  };

  getTripPieces = (accessToken, id) => {   
    return wd_axios.get(`${base_url}tripPieces/${accessToken}/${id}`);
  };

  getTripAvailableDescriptions = (accessToken, id) => {
    return wd_axios.get(`${base_url}tripAvailableDescriptions/${accessToken}/${id}`);
  };

  getSearchPreDefined = (accessToken) => {   
    return wd_axios.get(`${base_url}preDefinedSearch/${accessToken}`);
  };


  getTrip = (accessToken, id) => {    
    return wd_axios.get(`${base_url}tripDetail/${accessToken}/${id}`);
  };

  getTripAccountList = (accessToken, id) => {
    return wd_axios.get(`${base_url}tripAccountList/${accessToken}/${id}`);
  };

  getAccountList = (accessToken) => {
    return wd_axios.get(`${base_url}accountList/${accessToken}`);   
  };

  getAccountWebToken = (accessToken, accountNumber) => {
    return wd_axios.get(`${base_url}accountToken/${accessToken}/${accountNumber}`);
  };

  getSearchJobID = (accessToken, searchedJobID) => {
    return wd_axios.get(`${base_url}jobIDSearch/${accessToken}/${searchedJobID}`);
  };
 
  getmapPointsJobID = (accessToken, jobID, driverNumber) => {
    return wd_axios.get(base_url + "mapPointsJobID/" + accessToken + "/" +  driverNumber + "/" + jobID);
  };

  getMapPointsAll = (accessToken, companyID) => {
    return wd_axios.get(`${base_url}mapPointsAll/${accessToken}/${companyID}`);
  };

  getMapPointsByBoard = (accessToken, companyID, drvGroupID, boardID) => {
    return wd_axios.get(base_url + "mapPointsAll/" + accessToken + "/" +  companyID +  "/" + drvGroupID + "/" + boardID);
  };

  getMapPointsByDrvGroup = (accessToken, companyID, drvGroupID) => {
    return wd_axios.get(base_url + "mapPointsAll/" + accessToken + "/" + companyID + "/" + drvGroupID);
  };

  getDriverGroup = (accessToken) => {   
    return wd_axios.get(`${base_url}driverGroups/${accessToken}`);   
  };

  getMessagesFromDriver = (accessToken, companyID, driverNum) => {
    return wd_axios.get(`${base_url}messagesDriver/${accessToken}/${companyID}/${driverNum}`);
  };

  getServiceMessages = (accessToken) => {
    return wd_axios.get(base_url + "serviceMessages/" + accessToken);
  };

  getDriverStats = (accessToken, companyID) => {
    return wd_axios.get(`${base_url}driverStats/${accessToken}/${companyID}`);
  };

  getTripStats = (accessToken, companyID) => {
    return wd_axios.get(`${base_url}tripStats/${accessToken}/${companyID}`);
  };

  getTripsDispatch = (accessToken, boardID) => {
    return wd_axios.get(base_url + "tripsDispatch/" + accessToken + "/" + boardID);
  };

  getTripListFunctions = (accessToken, id) => {
    return wd_axios.get(base_url + "tripListFunctions/" + accessToken);
  };

  getTripAvailableRatingItems = (accessToken, id) => {
    return wd_axios.get(`${base_url}tripAvailableRatingItems/${accessToken}/${id}`);
  };

  getDispatchBoards = (accessToken) => {
    return wd_axios.get(base_url + "dispatchBoards/" + accessToken);
  };

  getRateChoice = (accessToken, id) => {
    return wd_axios.get(base_url + "rateChoice/" + accessToken + "/" + id);
  };

  getTripServiceNotes = (accessToken, id) => {
    return wd_axios.get(base_url + "tripServiceNotes/" + accessToken + "/" + id);
  };

  getTripPackageDescriptions = (accessToken, id) => {
    return wd_axios.get(`${base_url}tripPackageDescriptions/${accessToken}/${id}`);
  };

  getTripDocuments = (accessToken, id) => {
    return wd_axios.get(base_url + "tripDocuments/" + accessToken + "/" + id);
  };

  getTripTags = (accessToken, jobID, displayArea) => {
    return wd_axios.get(`${base_url}tripTags/${accessToken}/${jobID}/${displayArea}`);
  };

  getUserSettings = (accessToken, settingsKey) => {
    return wd_axios.get(`${base_url}userSettings/${accessToken}/${settingsKey}`);
  };

  getUserCompanyList = (accessToken) => {
    return wd_axios.get(base_url + "companyList/" + accessToken);
  };

  getReferenceFields = (accessToken, jobID) => {
    return wd_axios.get(base_url + "referenceFields/" + accessToken + "/" + jobID);
  };

  getReferenceDropDown = (accessToken, refID) => {
    return wd_axios.get(`${base_url}referenceDropDown/${accessToken}/${refID}`);
  };

  getFedexOrderDetail = (trackingNumber) => {
    return wd_axios.get(base_url + "fedexOrderDetail/" + trackingNumber);
  };

  tripSearch = (accessToken, searchParams) => {
    return wd_axios.post(base_url + "tripSearch/" + accessToken, searchParams);
  };

  jobIDListSearch= (accessToken, jobID_List) => {
    var content = {     
      jobIDList: jobID_List,
    };
    return wd_axios.post(base_url + "jobIDListSearch/" + accessToken, content);
  };

  doTripListFunction = (accessToken, jobID_List, params, procedureName) => {
    var content = {
      paramList: params,
      spName: procedureName,
      jobIDList: jobID_List,
    };
    return wd_axios.post(base_url + "doTripListFunction/" + accessToken, content);
  };

  doTripFunction = (accessToken, jobID, params, procedureName) => {
    var content = {
      paramList: params,
      spName: procedureName,
      jobIDList: "",
    };
    console.log("Final request payload:", JSON.stringify(content));
    return wd_axios.post(
      base_url + "doTripFunction/" + accessToken + "/" + jobID,
      content
    );
  };

  sendDriverMessage = (accessToken, drNum, companyID, jobID, message) => {
    var content = {
      messageText: message,
      jobID: jobID,
      drNum: drNum,
      companyID: companyID,
    };
    return wd_axios.post(base_url + "driverMessage/" + accessToken, content);
  };

  postUserSettings = (accessToken, settingsKey, filterQuery) => {
    var content = {
      settingsJson: filterQuery,
    };
    return wd_axios.post(
      base_url + "userSettings/" + accessToken + "/" + settingsKey,
      content
    );
    //return axios.post(base_url + 'userSettings/' + accessToken, content);
  };

  doUploadPicture = (accessToken, jobID, base64String, comment, blobURL) => {
    var content = {
      URL: blobURL,
      comment: comment,
    };
    return wd_axios.post(`${base_url}picture/${accessToken}/${jobID}`, content);
  };

  doUploadImage = (accessToken, base64String, blobFileName) => {
    var content = {
      base64: base64String,
      fileName: blobFileName,
    };
    return wd_axios.post(base_url + "image/" + accessToken, content);
  };

  /**
   * doUploadPDF
   * @param accessToken
   * @param params
   */
  doUploadFile = (accessToken, base64String, blobFileName) => {
    var content = {
      base64: base64String,
      fileName: blobFileName,
    };
    return wd_axios.post(base_url + "file/" + accessToken, content);
  };

  userUpdate = (accessToken, account, companyID, drNum) => {

    const utc = (new Date(new Date().toUTCString()).getTime())/1000;
    var content = {
      account: account,
      companyID: companyID,
      drNum: drNum,
    };
    return wd_axios.post(`${base_url}user/${accessToken}/${utc}`, content);
  };


  updateTripDescripion = (accessToken, jobId, descriptionID, serviceSettingsID) => {
    // Add timestamp to prevent duplicate calls
    const timestamp = new Date().getTime();
    const content = {
      jobID: jobId,
      descriptionID,
      serviceSettingsID,
      timestamp
    };
    console.log('Updating description with:', content);
    return wd_axios.put(`${base_url}tripDescripion/${accessToken}`, content);
  };

  getServiceQueBoards = (accessToken) => {
    console.log('Attempting to fetch service queue boards');
    console.log('Base URL:', base_url);
    console.log('Access Token:', accessToken);
  
    return wd_axios.get(`${base_url}serviceQueBoards/${accessToken}`, {
      headers: {
        'Accept': 'application/json',
        // If your API requires additional authentication headers
        // 'Authorization': `Bearer ${accessToken}` 
      },
      validateStatus: function (status) {
        return status >= 200 && status < 300; // Default
      }
    }).catch(error => {
      console.error('Error in getServiceQueBoards:', {
        message: error.message,
        response: error.response ? JSON.stringify(error.response, null, 2) : 'No response',
        request: error.request ? JSON.stringify(error.request, null, 2) : 'No request'
      });
      throw error;
    });
  };

  updateJobService = (accessToken, jobID, service) => {
    var content = {
      jobID: jobID,
      ser_area: service.ser_area,
      ser_time: service.ser_time,
    };
    return wd_axios.patch(`${base_url}orderRateFields/${accessToken}`, content);
  };

  // updateJobRateFields = (accessToken,order, jobId) => {
  //      let content = {
  //           ...order,
  //           jobID: jobId
  //      }
  //      console.log('>>>>>>>>>>>>updateJobRateFields--', JSON.stringify(content));
  //      return axios.patch(base_url + 'orderRateFields/' + accessToken, content)
  // }

  updateJobRateFields = (accessToken, order) => {
    console.log("updateJobRateFields", JSON.stringify(order));
    return wd_axios.patch(`${base_url}orderRateFields/${accessToken}`, order);
  };

  updateJobFields = (accessToken, order) => {
    return wd_axios.patch(`${base_url}orderFields/${accessToken}`, order);
  };

  clearServiceQueue = (accessToken, serviceQueID) => {
    return wd_axios.patch(`${base_url}serviceQueue/${accessToken}/${serviceQueID}`);
  };

  claimServiceQueue = (accessToken, serviceQueID) => {
    return wd_axios.patch( `${base_url}serviceQueueClaim/${accessToken}/${serviceQueID}` );
  };

  postReferenceFields = (accessToken, jobID, reference) => {
    var content = {
      jobID: jobID,
      reference: reference,
    };
    return wd_axios.patch(base_url + "referenceFields/" + accessToken, content);
  };

  postAccountDetail = (accessToken, accountDetail) => {   
    return wd_axios.post(base_url + "accountDetail/" + accessToken, accountDetail);
  };

  postTripAddedTag = (accesstoken, id,  content) => {
    return wd_axios.post(`${base_url}tripAddTag/${accesstoken}/${id}`, content);
  };

  postTripAddedRateItem = (accesstoken, id,  content) => {
    return wd_axios.post(`${base_url}addRatingItem/${accesstoken}/${id}`, content);
  };

  updateTripTag = (accesstoken, id,  content) => {
    return wd_axios.post(`${base_url}updateTripTag/${accesstoken}/${id}`, content);
  };


  getMessagesAll = (
    accesstoken,
    companyID,
    startTime,
    maxRecord,
    msgSearch
  ) => {
    var content = {
      since: startTime,
      take: maxRecord,
      text: msgSearch,
    };
    return wd_axios.post(`${base_url}messagesAll/${accesstoken}/${companyID}`, content);
  };

  updateDriverShareCount = (accesstoken, item) => {
    return wd_axios.post(base_url + "driverShareCount/" + accesstoken, item);
  };

  updateZrateExempt = (accesstoken, jobID, item) => {
    return wd_axios.post(`${base_url}zrateExempt/${accesstoken}/${jobID}`, item);
  };

  setHiddenImage = (accesstoken, item) => {
    return wd_axios.post(base_url + "imageHidden/" + accesstoken, item);
  };

  updateRatingItems = (accessToken, id, content) => {
    return wd_axios.put(`${base_url}tripRatingItem/${accessToken}/${id}`, content);
  };

  updateTripPieces = (accessToken, id, content) => {
    return wd_axios.put(`${base_url}tripPieces/${accessToken}/${id}`, content);
  };


  /* breadcrumbPoints post*/
  getBreadcrumbPointsWithRange = (accessToken, drNum, startTime, endTime) => {
    var content = {
      startTimeUTC: startTime,
      endTimeUTC: endTime     
    };
    return wd_axios.post(`${base_url}breadcrumbPoints/${accessToken}/${drNum}`, content );
  };

  /* breadcrumbPoints post*/
  getBreadcrumbPoints = (accessToken, drNum) => {    
    return wd_axios.post(`${base_url}breadcrumbPoints/${accessToken}/${drNum}`, {});
  };


  //Web5 API calls
  /* updateAddressContact  -- tested*/
  updateAddressContact = (accountNumber, addressID, contactID) => {
    var accessToken = localStorage.getItem("access_token");
    var content = {
      addressID: addressID,
      contactID: contactID,
    };
    return wd_axios.post(`${base_url}addressContact/${accountNumber}/${accessToken}`, content);
    //return axios.post(base_url + "addressContact/" + accountNumber, content);
  };

  /* addContact  ---------------*/
  addContact = (accountNumber, contact) => {
    var accessToken = localStorage.getItem("access_token");
    var content = {
      contact: contact,
      accountNumber: accountNumber,
    };
    return wd_axios.post(base_url + "contact" + "/" + accessToken, content);
    //return axios.post(base_url + "contact", content);
  };

  /* get Order Track  - tested */
  getOrderTrack = (orderID) => {
    var accessToken = localStorage.getItem("access_token");
    return wd_axios.get(base_url + "track/" + orderID + "/" + accessToken);
    //  return axios.get(base_url + "track/" + orderID); //wd5_base_url
  };

  /**
   * Post Address - tested
   * @param accountNumber
   **/
  postAddress = (accountNumber, address) => {
    var accessToken = localStorage.getItem("access_token");
    var content = {
      address: address,
      accountNumber: accountNumber,
    };
    return wd_axios.post(base_url + "address" + "/" + accessToken, content);
    // return axios.post(base_url + "address", content); //wd5_base_url
  };

  /**
   * post address with addressID and google return json string  -- tested
   * @param addressID
   * @param addrSearchResult
   */

  postAddressEXT = (addressID, addrSearchResult) => {
    var accessToken = localStorage.getItem("access_token");
    var content = {
      addrSearchResult: addrSearchResult,
      addressID: addressID,
    };
    return wd_axios.post(base_url + "addressEXT" + "/" + accessToken, content);
    //return axios.post(base_url + "addressEXT", content);
  };

  /**
   * get Job GPS - tested
   * @param orderID
   **/
  getJobGPS = (orderID) => {
    var accessToken = localStorage.getItem("access_token");
    return wd_axios.get(base_url + "jobGPS/" + orderID + "/" + accessToken);
  };

  /**
   * get Caller list - tested
   * @param account
   **/
  getUserEmails = (account) => {
    var accessToken = localStorage.getItem("access_token");
    return wd_axios.get(base_url + "userEmails/" + account + "/" + accessToken);
    //return axios.get(wd5_base_url + "userEmails/" + account);
  };

   /**
   * add caller  
   **/
    postUserEmails = (accountNumber, name) => {
      var accessToken = localStorage.getItem("access_token");
      var content = {
        accountNumber: accountNumber,
        caller: {
          username:name,
          emailAddress: ""
        }      
      };
      return wd_axios.post(base_url + "userEmails/" + accessToken, content);
      //return axios.post(base_url +  "userEmails", content);
    };


  /**
   * remove Caller  
   **/
  removeCaller = (accountNumber, userName) => {
    var accessToken = localStorage.getItem("access_token");
    var content = {
      username: userName      
    };
    return wd_axios.post(base_url + "caller/" + accountNumber + "/" + accessToken, content);
    //return axios.post(base_url +  "caller/" + accountNumber, content);
  };

  /**
   * top addresses
   * @param accountNumber
   * @param search
   * @returns {*}
   */
  getTopAddress = (accountNumber, search) => {
    var accessToken = localStorage.getItem("access_token");
    return wd_axios.post(
      base_url + "topAddress/" + accountNumber + "/" + accessToken,
      search
    );
    //return axios.post(base_url + "topAddress/" + accountNumber, search);
  };

  getTopAddressByType = (accountNumber, search, type) => {
    var accessToken = localStorage.getItem("access_token");
    return wd_axios.post(`${base_url}topAddress/${accountNumber}/${type}/${accessToken}`,search);
    // return axios.post( base_url + "topAddress/" + accountNumber + "/" + type, search);
  };

  /*
   * expand top addresses
   * @param accountNumber
   * @param search
   * @returns {*}
   */
  getExpandAddress = (accountNumber, search, skip) => {
    var accessToken = localStorage.getItem("access_token");
    return wd_axios.post(`${base_url}expandAddress/${accountNumber}/${skip}/${accessToken}`,search);
    // return axios.post( base_url + "expandAddress/" + accountNumber + "/" + skip,  search );
  };

  importFile= (accessToken, content) => {    
    return wd_axios.post(base_url + "importFile/" + accessToken, content);
  };
  //content = {fileURL,fileNote,stagingCode,isLegacy}
  
  referenceListSearch= (accessToken, content) => {    
    return wd_axios.post(base_url + "referenceListSearch/" + accessToken, content);
  };
  //Content =  {refrenceList, account}
  
}


const httpRequest = new HttpRequest();

export default httpRequest;



