import React, { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import DataTable from "./deDataGrid";
import SideButtons from "./sideButtons";
import "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import Copyright from "../Copyright";
import DateFnsUtils from "@date-io/date-fns";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { Grid,Button, Switch } from "@material-ui/core";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import NoticeEmitter from "../NoticeUtil.js";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/ClearAll";
import SaveIcon from "@mui/icons-material/SaveAlt";
import MapIcon from "@mui/icons-material/Map";
import RefreshIcon from "@mui/icons-material/Refresh";
import { debounce } from 'lodash';

import DEDataGrid from "./deDataGrid";
import DEDataGrid2 from "./deDataGrid2.js";

import SideButtonDialog from "./sideButtonDialog.js";
import CustomDialog from "../components/dialog/Dialog.js";

import SearchButtons from "./SearchButtons.js";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import HttpRequest from "../HttpService";

// import Drawer from '@mui/material/Drawer';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from "@mui/material/IconButton";
import Tooltip from '@mui/material/Tooltip';

import SideMenu from "../jobDetail/components/SideMenu.js";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
  Text: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: 10,
    },
    fontSize: 14,
  },
  dropDownFormControl: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
  invisible: {
    display: "none",
  },
  buttonGroup: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  container: {
    display: "flex",
    width: "100%",
    minHeight: "100vh",
    backgroundColor: "#f5f5f5",
    position: "relative"
  },
  sidePanel: {
    position: "fixed",
    top: 64, // Adjust if your header height is different
    left: 0,
    bottom: 0,
    width: ({ isDrawerOpen }) => isDrawerOpen ? "200px" : "60px",
    transition: "width 0.3s",
    backgroundColor: "#1a1a1a",
    zIndex: 1000
  },
  mainContent: {
    flexGrow: 1,
    marginLeft: ({ isDrawerOpen }) => isDrawerOpen ? "200px" : "60px",
    transition: "margin-left 0.3s",
    width: ({ isDrawerOpen }) => `calc(100% - ${isDrawerOpen ? "200px" : "60px"})`,
    position: "relative"
  },
  accordionButtons: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    padding: theme.spacing(1),
    "& .iconButton-purple": {
      backgroundColor: "#9c27b0",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#7b1fa2"
      },
      "&.Mui-disabled": {
        backgroundColor: "rgba(156, 39, 176, 0.3)",
        color: "rgba(255, 255, 255, 0.7)"
      }
    },
    "& .iconButton-green": {
      backgroundColor: "#4caf50",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#388e3c"
      }
    },
    "& .iconButton-red": {
      backgroundColor: "#f44336",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#d32f2f"
      }
    },
    "& .iconButton-orange": {
      backgroundColor: "#ff9800",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#f57c00"
      }
    }
  }
}));


export default function Search(props) {

  const classes = useStyles();

  const getInternalType = (urlType) => {
    switch(urlType) {
      case 'jobid-list': return 'jobIdList';  
      case 'reference-list': return 'referenceList';
      case 'predefined': return 'pre-defined';
      case 'standard': 
      default: return 'standard';
    }
  };
  
  const getUrlType = (internalType) => {
    switch(internalType) {
      case 'jobIdList': return 'jobid-list';
      case 'referenceList': return 'reference-list';
      case 'pre-defined': return 'predefined';
      case 'standard': 
      default: return 'standard';
    }
  };

  const safeDate = (date) => {
    if (!date) return new Date();
    try {
      const parsedDate = new Date(date);
      return isNaN(parsedDate.getTime()) ? new Date() : parsedDate;
    } catch (e) {
      console.error("Date parsing error:", e);
      return new Date();
    }
  };

  const [localSearchParams, setLocalSearchParams] = useState({
    account: "",
    order: "",
    billing: "",
    caller: "",
    addressID: "",
    serviceArea: "",
    serviceTime: "",
    drNum: "",
    shipperToFrom: "",
    phone: "",
    name: "",
    date: safeDate(new Date()),
    startDate: safeDate(new Date()),
    endDate: safeDate(new Date()),
    searchByRange: 3,
    driverSearchType: "any",
    location: -1,
    status: -1,
    activeTrip: true,
    exception: false,
    company: 1,
    daysBack: 9,
  });

  const [preDefinedOptions, setPreDefinedOptions] = useState([]);
  const [trips, setTrips] = useState([]);
  const [selection, setSelection] = useState([]);
  const [buttons, setButtons] = useState([]);
  const [startedSearch, setStartedSearch] = useState(false);

  const [defaultChecked, setDefaultChecked] = useState(true);
  const [dispatchChecked, setDispatchChecked] = useState(false);
  const [verficationChecked, setVerificationChecked] = useState(false);

  const [showIndicator, setShowIndicator] = useState(false);

  const [stateReset, setStateReset] = useState(false);
  const params = useParams();
  const urlPath = window.location.pathname;
  const [searchTypeFromUrl, searchNameFromUrl] = urlPath.split('/').slice(2);
  const isPreDefinedSearch = searchTypeFromUrl === 'predefined';  const history = useHistory();
  const [searchType, setSearchType] = useState(getInternalType(searchTypeFromUrl));

  const [jobIDList, setJobIDList] = useState("");
  const [statusDDList, setStatusDDList] = useState(null);
  const [locationDDList, setLocationDDList] = useState(null);
  const driverSearchType = [{value: "first", label:"First Name"},{value: "last", label:"Last Name"},{value: "any", label:"Any"}];
 
  const [referenceList, setReferenceList] = useState("");
  const [referenceAccount, setReferenceAccount] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [openSnack, setOpenSnack] = useState(false);
  const [messageInfo, setMessageInfo] = useState("");
  const [selectedButton, setSelectedButton] = useState(null);
  const [openActionDialog, setOpenActionDialog] = useState(false);
  const [actionDialogMessage, setActionDialogMessage] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isOptionsLoaded, setIsOptionsLoaded] = useState(false);

  const [useSecondGrid, setUseSecondGrid] = useState(() => {
    const saved = localStorage.getItem('useSecondGrid');
    return saved !== null ? JSON.parse(saved) : false;
  });
  
  useEffect(() => {
    localStorage.setItem('useSecondGrid', JSON.stringify(useSecondGrid));
  }, [useSecondGrid]);
  
  const handleToggle = () => {
    setUseSecondGrid(prevState => !prevState);
  };


  useEffect(() => {
    const timer = setTimeout(() => {
      document.title = "Search";
    }, 100);
  
    let token = localStorage.getItem("access_token");
    if (token === undefined || token === null) window.location.href = "/";
    
    try {
      // Load all the supporting data regardless
      HttpRequest.getSearchPreDefined(token).then(async function (body) {        
        let sList = body.data.map((option, index) => {
          option.id = index;
          return option;
        });    
        setPreDefinedOptions(sList);
      });
  
      HttpRequest.getTripListFunctions(token).then(async function (body) {        
        let buttonList = body.data.map((button, index) => {
          button.id = index;
          return button;
        });    
        setButtons(buttonList);
      });
  
      HttpRequest.getStatusDD(token).then(async function (body) {    
        let statusList = body.data.map((item) => {         
          return {value:item.status, label:item.descr};
        });    
        statusList.unshift({value:-1, label:"ALL"});
        setStatusDDList(statusList);        
        localStorage.setItem("statusDDList", JSON.stringify(statusList)); 
      });
  
      HttpRequest.getLocationDD(token).then(async function (body) {    
        let locList = body.data.map((item) => {         
          return {value:item.location, label:item.descr};
        });   
        locList.unshift({value:-1, label:"ALL"});
        setLocationDDList(locList);         
        localStorage.setItem("locationDDList", JSON.stringify(locList)); 
      });
  
      // Only do initial search if we're not loading from a URL
      if (!searchTypeFromUrl) {
        var retrievedSearchParams = localStorage.getItem("searchParameter");
        if (retrievedSearchParams !== "") {
          try {
            var searchParams = JSON.parse(retrievedSearchParams);
            if (searchParams) {
              searchParams.date = safeDate(searchParams.date);
              searchParams.startDate = safeDate(searchParams.startDate);
              searchParams.endDate = safeDate(searchParams.endDate);
              setLocalSearchParams(searchParams);
              queryTrips(searchParams);
            } else {
              queryTrips(localSearchParams);
            }
          } catch (e) {
            console.error("Error parsing stored search params:", e);
            queryTrips(localSearchParams);
          }
        } else {
          queryTrips(localSearchParams);
        }
      }
    } catch (e) {
      console.log("search page exception: " + e);
    }
  
    return () => clearTimeout(timer);
  }, []); // Empty dependency array since this should only run on mount

  

  useEffect(() => {
    console.log('URL path:', urlPath);
    console.log('Parsed params:', { searchTypeFromUrl, searchNameFromUrl });
    
    if (isPreDefinedSearch && searchNameFromUrl && preDefinedOptions.length > 0) {
      setSearchType('pre-defined');  // Explicitly set to 'pre-defined'
      const predefinedSearch = preDefinedOptions.find(
        search => search.buttonText.toLowerCase().replace(/\s+/g, '-') === searchNameFromUrl.toLowerCase()
      );
      
      if (predefinedSearch) {
        preDefinedSearch(predefinedSearch.searchID);
      }
    } else if (searchTypeFromUrl) {
      const type = getInternalType(searchTypeFromUrl);  // This is key
      setSearchType(type);
      
      switch(type) {
        case 'jobIdList':
          jobIDListSearch();
          break;
        case 'referenceList':
          referenceListSearch();
          break;
        case 'standard':
          queryTrips(localSearchParams);
          break;
        case 'pre-defined':
          // Potentially add logic here if needed
          break;
      }
    }
  }, [searchTypeFromUrl, searchNameFromUrl, preDefinedOptions]);

  const row1 = [
    ["Account", "account"],
    ["Order ID", "order"],
    ["Billing Reference", "billing"],
    ["Caller", "caller"],    
    ["Service Area", "serviceArea"],
    ["Service Time", "serviceTime"],
    ["Driver #", "drNum"],
  ];

  const row2 = [
    ["Shipper to From", "shipperToFrom"],
    ["AddressID", "addressID"],
    ["Contact Phone", "phone"],
    ["Contact Name", "name"],
  ];

  const saveSearchQuery = () => {
    console.log("postUserSettings-" + JSON.stringify(localSearchParams));
    HttpRequest.postUserSettings(localStorage.getItem("access_token"),"000",JSON.stringify(localSearchParams)).then((res) => {
      console.log("postUserSettings" + JSON.stringify(res.data));
    });
  };

  const showMap = () => {
    localStorage.setItem("orderListInMap", JSON.stringify(selection));
    let inxURL = "/orderListMap";
    window.open(inxURL, "_blank");
  };

  const handleSearch = () => {
    if (searchType === "standard") {
      debouncedSearch(localSearchParams);
    } else if (searchType === "jobIdList") {
      debouncedSearch({ jobIDList });
    } else if (searchType === "referenceList") {
      debouncedSearch({ referenceList, referenceAccount });
    }
  };

  const debouncedSearch = useCallback(
    debounce((params) => {
      queryTrips(params);
    }, 500),
    []
  );

  const formatDateString = (date) => {
    if (!date) return '';
    try {
      const parsedDate = new Date(date);
      if (isNaN(parsedDate.getTime())) return '';
      return Moment(parsedDate).format("YYYY-MM-DD");
    } catch (e) {
      console.error("Date formatting error:", e);
      return '';
    }
  };
  
  const referenceListSearch = () => {
    // Clear selection and state first
    setSelection([]);
    setStateReset(true);
    setTrips([]); // Clear trips before loading new ones
    
    setShowIndicator(true);
    
    const content = {
      referenceList: referenceList || '',
      account: referenceAccount || ''
    };
    
    HttpRequest.referenceListSearch(localStorage.getItem("access_token"), content)
      .then(async function (body) {
        let trips = body.data.map((trip, index) => {
          trip.id = index;
          trip.dtCallIn = Moment(trip.dtCallIn_UTC).format("YY-MM-DD HH:mm");
          return trip;
        });
        setTrips(trips);
        setStartedSearch(true);
        setShowIndicator(false);
      })
      .catch(error => {
        console.error("Reference List Search error:", error);
        setShowIndicator(false);
      });
  };

  const jobIDListSearch = () => {
    // Clear selection and state first
    setSelection([]);
    setStateReset(true);
    setTrips([]); // Clear trips before loading new ones
  
    setShowIndicator(true);
    HttpRequest.jobIDListSearch(localStorage.getItem("access_token"), jobIDList || '')
      .then(async function (body) {
        let trips = body.data.map((trip, index) => {
          trip.id = index;
          trip.dtCallIn = Moment(trip.dtCallIn_UTC).format("YY-MM-DD HH:mm");
          return trip;
        });    
        setTrips(trips);
        setStartedSearch(true);
        setShowIndicator(false);
      });
  }

  const clearSearch = () => {
    if(searchType === "standard") {
      const defaultParams = {
        account: "",
        order: "",
        billing: "",
        caller: "",
        addressID: "",
        serviceArea: "",
        serviceTime: "",
        drNum: "",
        shipperToFrom: "",
        phone: "",
        name: "",
        date: new Date(),
        startDate: new Date(),
        endDate: new Date(),
        searchByRange: 3,
        driverSearchType: "any",
        location: -1,
        status: -1,
        activeTrip: true,
        exception: false,
        company: 1,
        daysBack: 9,
      };
      setLocalSearchParams(defaultParams);
      localStorage.setItem("searchParameter", "");
      queryTrips(defaultParams);
    } else if (searchType === "jobIdList") {
      setJobIDList("");
      // Query with empty jobID list to get default results
      HttpRequest.jobIDListSearch(localStorage.getItem("access_token"), "")
        .then(async function (body) {
          const trips = body.data.map((trip, index) => ({
            ...trip,
            id: index,
            dtCallIn: Moment(trip.dtCallIn_UTC).format("YY-MM-DD HH:mm")
          }));
          setTrips(trips);
          setStartedSearch(true);
        });
    } else if (searchType === "referenceList") {
      setReferenceList("");
      setReferenceAccount("");
      // Query with empty reference list to get default results
      HttpRequest.referenceListSearch(localStorage.getItem("access_token"), { 
        referenceList: "",
        account: "" 
      }).then(async function (body) {
        const trips = body.data.map((trip, index) => ({
          ...trip,
          id: index,
          dtCallIn: Moment(trip.dtCallIn_UTC).format("YY-MM-DD HH:mm")
        }));
        setTrips(trips);
        setStartedSearch(true);
      });
    }
  };

  const actionFunction = () => {
    if (selectedButton.paramaters === "{}") {
      console.log(" actionFunction button paramters is " + selectedButton.paramaters);
      setOpenActionDialog(false);
      
      var jobIDList = '';
      selection.forEach((item) => {
        jobIDList += item.jobID + ',';
      });
      
      console.log("Procedure is " + selectedButton.procedureName);
      HttpRequest.doTripListFunction(props.accessToken, jobIDList, [], selectedButton.procedureName)
        .then(function successCallback(data) {
          console.log("Procedure is done for job/jobs " + jobIDList);
          setMessageInfo("Procedure is done for job/jobs " + jobIDList);
          setOpenSnack(true);
          NoticeEmitter.emit("resetTable", "");
        }, function errorCallback(err) {
          alert('Failed to : ' + err);
        });
    } else {
      setOpenActionDialog(false);
      setIsDialogOpen(true);
    }
  };

  const renderSearchButton = (field) => {
    return (
        <FormControl className="fieldWrapper">
          <TextField
            variant="outlined"
            key={field[1]}
            InputProps={{ classes: { input: classes.Text } }}
            margin="dense"
            label={<Typography className={classes.Text}>{field[0]}</Typography>}
            value={localSearchParams[field[1]]}
            className={classes.textField}
            onChange={(searchedVal) =>
              setLocalSearchParams((prevState) => {
                return {
                  ...prevState,
                  [field[1]]: searchedVal.target.value,
                };
              })
            }
          />
        </FormControl>
    );
  };
/*{
        "buttonText": "RVT Sort",
        "searchID": 1
    },
    */
    const renderPreDefinedSearchButton = (field) => {
      const searchUrlName = field.buttonText.toLowerCase().replace(/\s+/g, '-');
      return (
        <Box m={2}>     
          <Button 
            variant='contained' 
            size='large'  
            color='primary' 
            onClick={() => {
              setSearchType('pre-defined'); 
              history.push(`/search/predefined/${searchUrlName}`);
              preDefinedSearch(field.searchID);
            }}
          >
            {field.buttonText}
          </Button>
        </Box>
      );
    };


  const renderDropDown = (title, key, items) => {
    return (
      <FormControl className="fieldWrapper">
        <TextField
          variant="outlined"
          key={key}
          label={<Typography className={classes.Text}>{title}</Typography>}
          className={classes.textField}
          value={localSearchParams[key]}
          margin="dense"
          select
          onChange={(e) =>
            setLocalSearchParams((prevState) => {
              return {
                ...prevState,
                [key]: e.target.value,
              };
            })
          }
        >
          {items.map((choice) => (
            <MenuItem key={choice.value} value={choice.value}>
              <Typography className={classes.Text}>{choice.label}</Typography>
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    );
  };

  const preDefinedSearch = async (searchID) => {
    // Clear selection
    setSelection([]);
    
    // Trigger state reset in the grid
    setStateReset(true);
    
    const params = {searchID: searchID}  
    const trips = [];
    setShowIndicator(true);
    
    try {
      const body = await HttpRequest.tripSearch(localStorage.getItem("access_token"), params);
      const processedTrips = body.data.map((trip) => {
        trip.dtCallIn = Moment(trip.dtCallIn_UTC).format("YY-MM-DD HH:mm");
        return trip;
      });
      
      setTrips(processedTrips);
      setStartedSearch(true);
    } catch (error) {
      console.error("Error in preDefinedSearch:", error);
    } finally {
      setShowIndicator(false);
    }
  };
    


  const queryTrips = async (params) => {
    NoticeEmitter.emit("resetTable", "");
    setIsLoading(true);
    setShowIndicator(true);

    const setNewSearchParams = (params) => {
      let start, end;
      try {
        let temp = params.startDate ? safeDate(params.startDate) : new Date();
        if (!(temp instanceof Date) || isNaN(temp.getTime())) temp = new Date();
        start = new Date(temp.getFullYear(), temp.getMonth(), temp.getDate(), 0, 0);
        start = new Date(Moment(start).unix() * 1000);
        
        temp = params.endDate ? safeDate(params.endDate) : new Date();
        if (!(temp instanceof Date) || isNaN(temp.getTime())) temp = new Date();
        end = new Date(temp.getFullYear(), temp.getMonth(), temp.getDate(), 23, 59);
        end = new Date(Moment(end).unix() * 1000);
      } catch (e) {
        console.error("Date conversion error:", e);
        start = new Date();
        end = new Date();
      }
    
      const storageStatusList = JSON.parse(localStorage.getItem("statusDDList") || '[]');
      const storageLocationList = JSON.parse(localStorage.getItem("locationDDList") || '[]');
      const loc = storageLocationList?.find(item => item.value === params.location) || 
                  locationDDList?.find(item => item.value === params.location) || 
                  { value: -1, label: 'ALL' };
      const stat = storageStatusList?.find(item => item.value === params.status) || 
                  statusDDList?.find(item => item.value === params.status) || 
                  { value: -1, label: 'ALL' };
    
      if (params.searchByRange === 2 || params.searchByRange === 3) {
        return {
          account: params.account || '',
          jobID: params.order || '',
          cust_ref: params.billing || '',
          caller: params.caller || '',
          addressID: params.addressID || '',
          ser_area: params.serviceArea || '',
          ser_time: params.serviceTime || '',
          companyName: params.shipperToFrom || '',
          drNum: params.drNum || '',
          contactName: params.name || '',
          contactPhone: params.phone || '',
          drSearchType: params.driverSearchType || 'any',
          companyID: params.company || 1,
          exception: Boolean(params.exception),
          activeTrip: Boolean(params.activeTrip),
          exceptionFlag: String(Boolean(params.exception)),
          activeTripFlag: String(Boolean(params.activeTrip)),
          locationCode: String(params.location || -1),
          statusCode: String(params.status || -1),
          location: {
            locationCode: params.location || -1,
            descr: loc.label,
          },
          status: {
            statusCode: params.status || -1,
            descr: stat.label,
          },
          daysBack: params.searchByRange === 3 ? (params.daysBack || null) : null,
          startDate: start,
          endDate: end,
        };
      }
      
      if (params.searchByRange === 1) {
        return {
          account: params.account || '',
          jobID: params.order || '',
          cust_ref: params.billing || '',
          caller: params.caller || '',
          addressID: params.addressID || '',
          ser_area: params.serviceArea || '',
          ser_time: params.serviceTime || '',
          companyName: params.shipperToFrom || '',
          drNum: params.drNum || '',
          contactName: params.name || '',
          contactPhone: params.phone || '',
          drSearchType: params.driverSearchType || 'any',
          companyID: params.company || 1,
          exception: Boolean(params.exception),
          activeTrip: Boolean(params.activeTrip),
          exceptionFlag: String(Boolean(params.exception)),
          activeTripFlag: String(Boolean(params.activeTrip)),
          locationCode: String(params.location || -1),
          statusCode: String(params.status || -1),
          daysBack: null,
          location: {
            locationCode: params.location || -1,
            descr: loc.label,
          },
          status: {
            statusCode: params.status || -1,
            descr: stat.label,
          },
          monthDate: safeDate(params.date || new Date()).toUTCString(),
        };
      }
    };

    try {
      const token = localStorage.getItem("access_token");
      console.log("tripSearch -" + JSON.stringify(setNewSearchParams(params)));
      const response = await HttpRequest.tripSearch(token, setNewSearchParams(params));
      
      if (response && Array.isArray(response.data)) {
          const processedTrips = response.data.map(trip => {
              try {
                  const dtCallIn = trip.dtCallIn_UTC ? 
                      Moment(trip.dtCallIn_UTC).format("YY-MM-DD HH:mm") : '';
                  return {
                      ...trip,
                      dtCallIn,
                      id: trip.id || 0
                  };
              } catch (error) {
                  console.error("Error processing trip:", error, trip);
                  return {
                      ...trip,
                      dtCallIn: '',
                      id: trip.id || 0
                  };
              }
          });
          
          localStorage.setItem("searchParameter", JSON.stringify(params));
          setTrips(processedTrips);
          setStartedSearch(true);
      } else {
          console.error("Invalid response data:", response);
          setTrips([]);
      }
  } catch (error) {
      console.error("Error fetching trips:", error);
  } finally {
      setIsLoading(false);
      setShowIndicator(false);
  }
};
    

const handleSearchTypeChange = (event) => {    
  const newType = event.target.value;
  
  // Clear everything first
  setTrips([]);  // Clear the data first
  setStartedSearch(false);
  setSelection([]);
  setStateReset(true);
  
  // Let the clearing operations complete before changing type and loading new data
  setTimeout(() => {
    setSearchType(newType);
    const urlType = getUrlType(newType);
    history.push(`/search/${urlType}`);
    
    switch(newType) {
      case 'standard':
        queryTrips(localSearchParams);
        break;
      case 'jobIdList':
        jobIDListSearch();
        break;
      case 'referenceList':
        referenceListSearch();
        break;
      case 'pre-defined':
        break;
    }
  }, 0);
};

  const renderIconButton = (title, onClickHandler, disabled, label, className, Icon) => {
    return (
      <Tooltip title={disabled ? "Select items to enable this action" : title}>
        <span> {/* Wrapper to ensure tooltip works when button is disabled */}
          <Button
            variant="contained"
            onClick={onClickHandler}
            disabled={disabled}
            aria-label={label}
            className={className}
            startIcon={<Icon />}
            style={{ margin: '0 8px', minWidth: '120px' }}
          >
            {title}
          </Button>
        </span>
      </Tooltip>
    );
  };

  return (
    <div className="container_search-list">
      <div style={{ display: "flex", zIndex: 2 }}>
        <div id="leftPanel" style={{ 
          width: isDrawerOpen ? "200px" : "60px", 
          transition: "width 0.3s",
          position: "fixed",
          left: 0,
          top: "64px",
          bottom: 0,
          backgroundColor: "#1a1a1a",
          zIndex: 1000 
        }}>
          {trips.length > 0 && (
        <SideMenu
          navigation={[{
            id: 'Process',
            text: 'Process',
            icon: 'fa fa-cogs',
            options: buttons
          }]}
          onMenuItemClick={(item) => {
            if (selection.length > 0) {
              const msg = selection.length === 1
                ? "1 job is selected."
                : `${selection.length} jobs are selected.`;
              setSelectedButton(item);
              setActionDialogMessage(msg);
              setOpenActionDialog(true);
            }
          }}
          storageKey="searchPage"
          disabledCondition={() => selection.length === 0}
          disabledMessage="Select orders to enable actions"
          onDrawerToggle={setIsDrawerOpen}
        />
      )}
        </div>

        <div className="main-content" style={{ 
          marginLeft: isDrawerOpen ? "200px" : "60px",
          transition: "margin-left 0.3s",
          width: `calc(100vw - ${isDrawerOpen ? "200px" : "60px"})` 
        }}>
                <div id="searchAppBar">
            <FormControl className="search-toggle_form">
              <SearchButtons.SearchTypeButtons 
                activeType={searchType} 
                onChange={handleSearchTypeChange}
              />
            </FormControl>
            <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
              <Typography style={{ color: 'white', marginRight: '10px' }}>Legacy</Typography>
              <Switch 
                checked={useSecondGrid} 
                onChange={handleToggle}
                color="default"
              />
              <Typography style={{ color: 'white', marginLeft: '10px' }}>Lattice</Typography>
            </div>
            <div className={classes.accordionButtons}>
              {renderIconButton(
                "View Map",
                showMap,
                selection.length === 0,
                "Map",
                "iconButton-purple",
                MapIcon
              )}
              {/* {renderIconButton(
                "Save Search",
                saveSearchQuery,
                false,
                "Save",
                "iconButton-green",
                SaveIcon
              )} */}
              {renderIconButton(
                "Clear Form",
                () => {
                  clearSearch();
                  setSelection([]);
                },
                false,
                "Clear",
                "iconButton-red",
                ClearIcon
              )}
              {renderIconButton(
                "Search Now",
                handleSearch,
                false,
                "Search",
                "iconButton-orange",
                SearchIcon
              )}
            </div>
          </div>

          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="colorPrimary" />}
            >
            </AccordionSummary>
            <AccordionDetails>
              {searchType === 'jobIdList' && (
                <Grid container direction="row" className="darkForm" justifyContent="start" alignItems="flex-start"
                  style={{ margin: "0", width: "100%" }} spacing={0}>
                  <FormControl className="fieldWrapper">
                    <TextField 
                      id="jobID" 
                      type="number" 
                      variant="outlined" 
                      InputProps={{ classes: { input: classes.Text } }} 
                      margin="dense"
                      label={<Typography className={classes.Text}>Add jobID</Typography>}
                      className={classes.textField}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          setJobIDList(jobIDList + e.target.value + ',')
                          document.getElementById('jobID').value = "";
                        }
                      }}
                    />
                  </FormControl>
                  <FormControl className="textareaWrapper">
                    <TextareaAutosize 
                      minRows={5} 
                      placeholder="jobID list"
                      value={jobIDList} 
                      onChange={e => { setJobIDList(e.target.value) }}
                    />
                  </FormControl>
                </Grid>
              )}

              {searchType === 'referenceList' && (
                <Grid container direction="row" className="darkForm" justifyContent="start" alignItems="flex-start"
                  style={{ margin: "0", width: "100%" }} spacing={0}>
                  <FormControl className="fieldWrapper">
                    <TextField 
                      id="reference"
                      variant="outlined"
                      InputProps={{ classes: { input: classes.Text } }}
                      margin="dense"
                      label={<Typography className={classes.Text}>Add Reference</Typography>}
                      className={classes.textField}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          setReferenceList(referenceList + e.target.value + ',')
                          document.getElementById('reference').value = "";
                        }
                      }}
                    />
                  </FormControl>
                  <FormControl className="fieldWrapper">
                    <TextField 
                      id="referenceAccount"
                      variant="outlined"
                      InputProps={{ classes: { input: classes.Text } }}
                      margin="dense"
                      label={<Typography className={classes.Text}>Account (Optional)</Typography>}
                      className={classes.textField}
                      onChange={(e) => setReferenceAccount(e.target.value)}
                    />
                  </FormControl>
                  <FormControl className="textareaWrapper">
                    <TextareaAutosize 
                      minRows={5}
                      placeholder="reference list"
                      value={referenceList}
                      onChange={e => { setReferenceList(e.target.value) }}
                    />
                  </FormControl>
                </Grid>
              )}

              {searchType === 'pre-defined' && (
                <SearchButtons.PreDefinedButtons
                  options={preDefinedOptions}
                  onSelect={(option) => {
                    setSearchType('pre-defined');
                    setSelection([]);
                    history.push(`/search/predefined/${option.buttonText.toLowerCase().replace(/\s+/g, '-')}`);
                    preDefinedSearch(option.searchID);
                  }}
                  searchNameFromUrl={searchNameFromUrl}
                />
              )}

              {searchType === 'standard' && (
                <Box display="flex" flexWrap="nowrap" flexDirection="column" m={0} >
                  <Box display="flex"  border={0} className={"darkForm"} sx={{ height: "auto" }}>
                    {row1.map((field) => renderSearchButton(field))}
                    {renderDropDown("Driver Search Type","driverSearchType",driverSearchType)}
                    {locationDDList && locationDDList.length > 0 && renderDropDown("Location","location",locationDDList)}
                    {statusDDList && statusDDList.length > 0 && renderDropDown("Status","status",statusDDList)}
                    {row2.map((field) => renderSearchButton(field))}
                    <div className="formRowBottom">
                      <TextField style={{ width: 150 }} variant="outlined" label={<Typography className={classes.Text}>Search Type</Typography> }
                        className={classes.textField}
                        margin="dense" select
                        value={localSearchParams.searchByRange}
                        onChange={(searchedVal) =>
                          setLocalSearchParams((prevState) => {
                            return {
                              ...prevState,
                              searchByRange: searchedVal.target.value,
                            };
                          })
                        }
                      >
                        <MenuItem value={1}>
                          <Typography className={classes.Text}>Month</Typography>
                        </MenuItem>
                        <MenuItem value={2}>
                          <Typography className={classes.Text}>Range</Typography>
                        </MenuItem>
                        <MenuItem value={3}>
                          <Typography className={classes.Text}>Days Back</Typography>
                        </MenuItem>
                      </TextField>
                      <TextField
                        variant="outlined"  margin="dense" label="Days Back"  type="number"
                        InputProps={{ classes: { input: classes.Text } }}
                        value={localSearchParams["daysBack"]}
                        className={localSearchParams.searchByRange === 3 ? classes.textField : classes.invisible}
                        onChange={(searchedVal) => {
                          setLocalSearchParams((prevState) => {
                            return {
                              ...prevState,
                              daysBack: searchedVal.target.value,
                              startDate: safeDate(new Date(new Date().getTime() - searchedVal.target.value * 24 * 60 * 60 * 1000)),
                              endDate: safeDate(new Date()),
                            };
                          });
                        }}
                      />
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>

                        <KeyboardDatePicker
                          inputVariant="outlined"
                          variant="inline"
                          size="small"
                          margin="dense"
                          label={<Typography className={classes.Text}>Start Date</Typography>}
                          value={Moment(localSearchParams.startDate).toDate()}
                          onError={console.log}
                          format="yyyy-MM-dd"
                          style={{ width: 180 }}
                          className={localSearchParams.searchByRange === 2 ? classes.textField : classes.invisible}
                          KeyboardButtonProps={{"aria-label": "change date"}}
                          onChange={(searchedVal) => {
                            setLocalSearchParams(prevState => ({
                              ...prevState,
                              startDate: searchedVal ? Moment(searchedVal).toDate() : new Date()
                            }));
                          }}
                        />

                        <KeyboardDatePicker
                          inputVariant="outlined"
                          variant="inline"
                          size="small"
                          margin="dense"
                          label={<Typography className={classes.Text}>End Date</Typography>}
                          value={Moment(localSearchParams.endDate).toDate()}
                          onError={console.log}
                          format="yyyy-MM-dd"
                          style={{ width: 180 }}
                          className={localSearchParams.searchByRange === 2 ? classes.textField : classes.invisible}
                          KeyboardButtonProps={{"aria-label": "change date"}}
                          onChange={(searchedVal) => {
                            setLocalSearchParams(prevState => ({
                              ...prevState,
                              endDate: searchedVal ? Moment(searchedVal).toDate() : new Date()
                            }));
                          }}
                        />

                        <KeyboardDatePicker
                          inputVariant="outlined"
                          variant="inline"
                          openTo="month"
                          views={["year", "month"]}
                          style={{ width: 150 }}
                          format="MM/yyyy"
                          margin="dense"
                          value={Moment(localSearchParams.date).toDate()}
                          label={<Typography className={classes.Text}>Month</Typography>}
                          className={localSearchParams.searchByRange === 1 ? classes.textField : classes.invisible}
                          onChange={(searchedVal) => {
                            setLocalSearchParams(prevState => ({
                              ...prevState,
                              date: searchedVal ? Moment(searchedVal).toDate() : new Date()
                            }));
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      {/* First wrapperCheckboxes - Active/Exception */}
                      <div className="wrapperCheckboxes">
                        <FormGroup row>
                          <FormControl>
                            <FormControlLabel
                              control={
                                <Checkbox label="Active Trip"
                                  checked={localSearchParams.activeTrip}
                                  color="primary"
                                  onChange={(searchedVal) => {                         
                                    let searchParams = {
                                      ...localSearchParams,
                                      daysBack: searchedVal.target.checked? 9 : localSearchParams.daysBack,
                                      activeTrip: searchedVal.target.checked,
                                    };                                  
                                    setLocalSearchParams(searchParams);
                                    queryTrips(searchParams);
                                  }}
                                />
                              }
                              label={<Typography>Active</Typography>}
                            />
                          </FormControl>
                          <FormControl>
                            <FormControlLabel
                              control={
                                <Checkbox  color="primary"  label="Exception"
                                  checked={localSearchParams.exception}
                                  onChange={(searchedVal) => {                        
                                    let searchParams = {
                                      ...localSearchParams,
                                      exception: searchedVal.target.checked,
                                    };
                                    setLocalSearchParams(searchParams);
                                    queryTrips(searchParams);
                                  }}
                                />
                              }
                              label={<Typography>Exception</Typography>}
                            />
                          </FormControl>
                        </FormGroup>
                      </div>

                      {/* Second wrapperCheckboxes - Default/Verification */}
                      <div className="wrapperCheckboxes">
                        <FormGroup row>
                          <FormControl>
                            <FormControlLabel
                              control={
                                <Checkbox label="Default" checked={defaultChecked} color="primary"
                                  onChange={(event) => {
                                    setDefaultChecked(event.target.checked);
                                    setVerificationChecked(false);
                                    setDispatchChecked(false);                         
                                    let searchParams = {
                                      ...localSearchParams,                           
                                      searchByRange: 3,
                                      daysBack: 9,
                                      activeTrip: true,
                                      status: -1,
                                    };
                                    setLocalSearchParams(searchParams);
                                    queryTrips(searchParams);
                                  }}
                                />
                              }
                              label={<Typography> {" "} Default </Typography>}
                            />
                          </FormControl>               
                          <FormControl>
                            <FormControlLabel
                              control={
                                <Checkbox color="primary" label="Verification"
                                  checked={verficationChecked}
                                  onChange={(event) => {
                                    setVerificationChecked(event.target.checked);
                                    setDispatchChecked(false);
                                    setDefaultChecked(false);                         
                                    let searchParams = {
                                      ...localSearchParams,
                                      searchByRange: 3,
                                      daysBack: 9,
                                      activeTrip: false,
                                      status: 8,
                                    };
                                    setLocalSearchParams(searchParams);
                                    queryTrips(searchParams);
                                  }}
                                />
                              }
                              label={<Typography>Verification</Typography>}
                            />
                          </FormControl>
                        </FormGroup>
                      </div>

                    </div>
                  </Box>
                </Box>
              )}
            </AccordionDetails>
          </Accordion>

          {showIndicator && (
            <Stack justifyContent="center" alignItems="center" spacing={2} direction="row">
              <CircularProgress />
            </Stack>
          )}

          {startedSearch && trips.length === 0 && (
            <div style={{width: "100%", display: "inline_block", backgroundColor: "#fff3cd", textAlign: "center" }}>
              <Typography variant="h6" style={{ color: "#a9a9a9" }}>
                {"No results found"}
              </Typography></div>
          )}

          {trips.length > 0 && (
            <div style={{ display: "block", position: "relative", flex: "1 1 auto", minHeight: "400px" }}>
              {useSecondGrid ? (
                <DEDataGrid2
                  trips={trips}
                  selection={selection}
                  setSelection={setSelection}
                  defaultChecked={defaultChecked}
                  dispatchChecked={dispatchChecked}
                  verficationChecked={verficationChecked}
                  stateReset={stateReset}
                  setStateReset={setStateReset}
                />
              ) : (
                <DEDataGrid
                  trips={trips}
                  selection={selection}
                  setSelection={setSelection}
                  defaultChecked={defaultChecked}
                  dispatchChecked={dispatchChecked}
                  verficationChecked={verficationChecked}
                  stateReset={stateReset}
                  setStateReset={setStateReset}
                />
              )}
            </div>
          )}
        </div>
      </div>

      {selectedButton !== null && (
        <SideButtonDialog
          button={selectedButton}
          open={isDialogOpen}
          selection={selection}
          accessToken={props.accessToken}
          handleDialogClose={() => setIsDialogOpen(false)}
        />
      )}

      <CustomDialog
        open={openActionDialog}
        onClose={() => {
          setOpenActionDialog(false);
        }}
        hasCloseBtn={true}
        dialogMessage={actionDialogMessage}
        dialogTitle="Warning"
        dialogAction={() => {
          actionFunction();
        }}
      />
      <footer>
        <Copyright />
      </footer>
    </div>    
  );
}