import React, { useState, useEffect, useRef } from "react";

import httpRequest from "../../HttpService";
import TextInput from "../../components/inputFields/TextInput";
import IconButton from "@material-ui/core/IconButton";
import DoneIcon from "@material-ui/icons/Done";
import DeleteIcon from "@material-ui/icons/Delete";
import CustomDialog from "../../components/dialog/Dialog";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";

const TripList = (props) => {
  const [trips, setTrips] = useState(props.tripList);
  const [assignedDriverID, setAssignedDriverID] = useState("");
  const [selectedTrip, setSelectedTrip] = useState(props.tripList[0]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  /*
    useEffect(() => {        
        getTripList();
    },[props.boardID]);
    */

    useEffect(() => {
      setTrips(props.tripList);
    }, [props.tripList]);

  /*
    useEffect(() => {  
        const interval = setInterval(() => {            
            getTripList();
        }, 60000);
        return () => clearInterval(interval);
    },[]);
    */

  const getTripList = () => {
    const tripList = [];
    httpRequest
      .getTripsDispatch(props.accessToken, props.boardID)
      .then(async function (body) {
        if (body.data.length !== 0) {
          await body.data.forEach((value) => {
            value.SER_AREA = value.SER_AREA + "-" + value.SER_TIME;
            if (value.priorJobID === null) {
              value.priorJobID = value.NextJobID;
            }
            value.id = tripList.length;
            value.assignedDriver = "";
            value.done = "";
            value.clear = "";
            tripList.push(value);
          });
          setTrips(tripList);
          setSelectedTrip(tripList[0]);
        } else setTrips([]);
      });
    };

  const handleADUpdate = (event) => {
    console.log("handleADUpdate-" + event.target.value);
    setAssignedDriverID(event.target.value);
  };

  const renderDone = (params) => {
    return (
      <IconButton>
        <DoneIcon />
      </IconButton>
    );
  };

  const renderClear = (params) => {
    return (
      <IconButton className="iconButtonClear">
        <DeleteIcon />
      </IconButton>
    );
  };

  const renderJobID = (params) => {
    return <a className="jobIDLink">{params.value} </a>;
  };

  const renderAssign = (params) => {
    // Add additional check for the field
    return (params.row === selectedTrip && params.field === "assignedDriver") ? (
      <TextInput
        variant="outlined"
        type="number"
        size="small"
        labelSize="0"
        onChange={handleADUpdate}
        hidelabel="true"
        value={assignedDriverID}
        onKeyPress={(e) => {
          try {
            if (e.key === "Enter") {
              doAssignTrip();
            }
          } catch (err) {
            console.error("renderAssign enter key-", err);
          }
        }}
        // Add autoFocus to ensure the input is ready for typing
        autoFocus
      />
    ) : (
      <div style={{ cursor: 'pointer' }}>Click to assign</div>
    );
  };

  const removeTrip = () => {
    const tempList = trips.filter((object) => {
      return object.jobID !== selectedTrip.jobID;
    });
    setTrips(tempList);
  };

  const overrideFunction = () => {
    var paramsList = [];
    paramsList.push({
      label: "drnum",
      prompt: "Driver Number",
      type: "varchar",
      length: 3,
      mo: "Driver Number",
      value: assignedDriverID,
    });
    paramsList.push({
      label: "override",
      prompt: "",
      type: "bit",
      length: 1,
      mo: "override",
      value: 1,
    });
    httpRequest
      .doTripFunction(
        props.accessToken,
        selectedTrip.jobID,
        paramsList,
        "in5.trip_assign"
      )
      .then(
        async (body) => {
          console.log("trip_assign procedure is done!");
          removeTrip();
          setTimeout(() => {
            getTripList();
          }, 3000);
        },
        function errorCallback(err) {
          alert("Failed to finish procedure. Error is : " + err);
        }
      );
  };

  const handleOnCellClick = (params) => {
    if (params.field === "assignedDriver") {
      // Explicitly set the selection when clicking the assign cell
      setSelectedTrip(params.row);
      setAssignedDriverID("");
    }
    //console.log(params.field + '---' + params.row.jobID);
    if (params.field === "jobID") {
      let inxURL = "/jobdetail/" + params.row.jobID;
      window.open(inxURL, "_blank");
    }
    if (params.field === "priorJobID") {
      let inxURL = "/jobdetail/" + params.row.priorJobID;
      window.open(inxURL, "_blank");
    }
    if (params.field === "clear") {
      setAssignedDriverID("");
    }
    if (params.field === "done") {
      try {
        //console.log(params.field + "-" + params.value + '---' + assignedDriverID + '---' + params.row.jobID + '-----' + selectedTrip.jobID);
        doAssignTrip();
      } catch (err) {
        alert("doAssignTrip exception. Error is : " + err);
      }
    }
  };

  const doAssignTrip = () => {
    try {
      if (assignedDriverID === "" || assignedDriverID < 0) return;
      var paramsList = [];
      paramsList.push({
        label: "drnum",
        prompt: "Driver Number",
        type: "varchar",
        length: 3,
        mo: "Driver Number",
        value: assignedDriverID,
      });
      httpRequest
        .doTripFunction(
          props.accessToken,
          selectedTrip.jobID,
          paramsList,
          "in5.trip_assign"
        )
        .then(
          async (body) => {
            //alert("trip_assign procedure is done!");
            console.log("doTripFunction-" + body.data);
            if (body.data !== "") {
              var res = body.data;
              console.log(res[0].ResultCode + "---" + res[0].Result);
              if (res[0].ResultCode === 10) {
                setDialogMessage(res[0].Result);
                setOpenDialog(true);
              }
            } else {
              removeTrip();
              setTimeout(() => {
                getTripList();
              }, 3000);
            }
          },
          function errorCallback(err) {
            alert("Failed to finish procedure. Error is : " + err);
          }
        );
    } catch (err) {
      alert("doAssignTrip exception. Error is : " + err);
    }
  };

  const columns = [
    {
      field: "jobID",
      headerName: "JobID",
      width: 100,
      renderCell: renderJobID,
    },
    {
      field: "assignedDriver",
      headerName: "Assign to Driver",
      width: 150,
      renderCell: renderAssign,
    },
    { field: "done", headerName: "", width: 60, renderCell: renderDone },
    { field: "clear", headerName: "", width: 60, renderCell: renderClear },
    { field: "sCompanyName", headerName: "Shipper", width: 220 },
    { field: "sAddress", headerName: "ShipperAddress", width: 180 },
    { field: "sQuad", headerName: "Squad", width: 80 },
    { field: "cCompanyName", headerName: "Consignee", width: 220 },
    { field: "cAddress", headerName: "ConsigneeAddress", width: 180 },
    { field: "cQuad", headerName: "CQuad", width: 80 },
    { field: "dtCallInDisplayString", headerName: "Called In", width: 170 },
    { field: "dtDCTDisplayString", headerName: "DCT", width: 170 },
    { field: "SER_AREA", headerName: "SvcType", width: 100 },
    { field: "Pieces", headerName: "Pcs", width: 80 },
    { field: "weight", headerName: "Wgt", width: 100 },
    { field: "DEL_INST", headerName: "Instructions", width: 220 },
    {
      field: "priorJobID",
      headerName: "PREV/NEXT",
      width: 120,
      renderCell: renderJobID,
    },
  ];

  return (
    <div className="darkDataGrid" style={{ height: 400, width: "100%" }}>
      <DataGridPro
        rows={trips}
        columns={columns}
        checkboxSelection={false}
        disableColumnSelector={true}
        disableColumnMenu={true}
        onCellClick={handleOnCellClick}
        onSelectionModelChange={(ids) => {
          try {
            const selectedIDs = new Set(ids);
            const selectedRowData = trips.filter((row) =>
              selectedIDs.has(row.id)
            );
            console.log("onSelectionModelChange-" + selectedRowData[0].jobID);
            if (selectedRowData[0].jobID !== selectedTrip.jobID)
              setAssignedDriverID("");
            setSelectedTrip(selectedRowData[0]);
          } catch (e) {
            console.log("tripList exception:" + e);
          }
        }}
      />
      {/* Custom Dialogs */}
      <CustomDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        hasCloseBtn={true}
        dialogMessage={dialogMessage}
        dialogTitle="Warning"
        dialogAction={() => {
          overrideFunction();
          setOpenDialog(false);
        }}
      />
    </div>
  );
};

export default TripList;

//https://stackoverflow.com/questions/70134706/styling-specific-column-of-the-mui-datagrid
//https://stackoverflow.com/questions/64331095/how-to-add-a-button-to-every-row-in-mui-datagrid
//https://codesandbox.io/s/festive-payne-fy3w8?file=/src/DataTable.js:200-275
