import React, { Component } from 'react';
import { withRouter } from "react-router";
import { Container} from '@material-ui/core';
import Copyright from './Copyright';

class WDWebview extends  React.Component {
    constructor(props) {
        super(props); 
        let web5_url =  localStorage.getItem('web5URL','https://ship.directx.ca');   
        this.state = {
            url: web5_url
        }           

        localStorage.setItem('searchParameter', "");       
        localStorage.setItem('seviceSearchParameter', "");                    
    }    
    
    componentDidMount = () =>  {  
        let token =  localStorage.getItem('access_token');
        if(token === undefined || token === null)
           window.location.href='/';        
    }

    render() {
       return (
           <div style={{
                width: '100%',
                height: '94vh',
                overflow: 'hidden',
            }}>
                <iframe
                    style={{
                        width: '100%',
                        height: '100%',
                        border: 'none',
                    }}
                    src={ this.state.url}
                />
                <footer style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    zIndex: 1,
                }}>
                    <Copyright/>
                </footer>
            </div>           
        );
    }
}

export default withRouter(WDWebview);