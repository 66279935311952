import React from 'react';
import { useState, useEffect, useCallback} from 'react';
import { useHistory  } from 'react-router-dom';
import Copyright from "../Copyright";
import DashboardBar from './DashboardBar';
import DashboardDatagrid from './DashboardDatagrid';
import DashboardMessageDialog from './DashboardSendMessageDialog';
import "date-fns";
import HttpRequest from "../HttpService";
import Moment from "moment";
import CustomDialog from "../components/dialog/Dialog";
import RefreshTimer from './RefreshTimer';

export default function Dashboard(props) { 
    const history = useHistory(); 

    const accessToken = localStorage.getItem("access_token");
    const [companyID, setCompanyID] = useState(props.companyID);
    const [tripStats, setTripStates] = useState({});
    const [messages, setMessages] =  useState([]);
    const [orgMessages, setOrgMessages] = useState([]);
    const [msgBoxOpen, setMsgBoxOpen] = useState(false);
    const [msgToDriver, setMsgToDriver] = useState("");
    const [selectedDriver, setSelectedDriver] = useState("");
    const [serviceChecked, setServiceChecked] = useState(false);
    const [dispatchChecked, setDispatchChecked] = useState(false);
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [salesChecked, setSalesChecked] = useState(false);
    const [dcChecked, setDcChecked] = useState(false);
    const [autoRefreshEnabled, setAutoRefreshEnabled] = useState(true);

    const sortMessages = useCallback((msgs) => {
        return [...msgs].sort((a, b) => {
            if (a.priority !== b.priority) {
                return b.priority - a.priority;
            }
            return new Date(a.datestamp) - new Date(b.datestamp);
        });
    }, []);

    const applyFilters = useCallback((messageList, service, dispatch, sales, dc) => {
        let filteredList = [...messageList];
    
        if (service || dispatch || sales || dc) {
            filteredList = filteredList.filter(msg => 
                (service && msg.messageType === 1) ||
                (dispatch && msg.messageType === 2) ||
                (sales && msg.messageType === 3) ||
                (dc && msg.messageType === 4)
            );
        }
    
        return sortMessages(filteredList);
    }, [sortMessages]);

    useEffect(() => {
        setCompanyID(props.companyID);
    }, [props.companyID]);

    const pollData = useCallback(async () => {
        try {
            const resDrv = await HttpRequest.getDriverStats(accessToken, companyID);
            if(resDrv.data){
                setTripStates(prevState => ({
                    ...prevState,
                    DriversLoggedIn: resDrv.data.length,
                }));
            }  
    
            const statsRes = await HttpRequest.getTripStats(accessToken, companyID);
            if(statsRes.data){
                setTripStates(prevState => ({
                    ...prevState,
                    ...statsRes.data[0]
                })); 
            }    
                   
            const serviceMsgRes = await HttpRequest.getServiceMessages(accessToken);
            if(serviceMsgRes.data){
                let assignTripsCount = 0;
                const mList = serviceMsgRes.data.map((obj, index) => {
                    if(obj.messageText.includes("Trip Requires Dispatch (no Auto)")) {
                        assignTripsCount++;   
                    }
                    return {
                        ...obj,
                        datestamp: Moment(obj.datestamp).format("YYYY-MM-DD HH:mm"),
                        id: index
                    };
                });
    
                setOrgMessages(mList);
                setTripStates(prevState => ({
                    ...prevState,
                    ServiceAlerts: mList.length,
                    tripAssignStats: assignTripsCount
                }));
            }           
        } catch (error) {
            console.error("Error polling data:", error);
        }
    }, [accessToken, companyID]);

    useEffect(() => {
        let activeFilters = [];
        if (serviceChecked) activeFilters.push(1);
        if (dispatchChecked) activeFilters.push(2);
        if (salesChecked) activeFilters.push(3);
        if (dcChecked) activeFilters.push(4);
    
        let filteredMessages = [...orgMessages];
        
        if (activeFilters.length > 0) {
            filteredMessages = filteredMessages.filter(msg => 
                activeFilters.includes(msg.messageType)
            );
        }
    
        filteredMessages.sort((a, b) => {
            if (a.priority !== b.priority) {
                return b.priority - a.priority;
            }
            return new Date(a.datestamp) - new Date(b.datestamp);
        });
    
        setMessages(filteredMessages);
    }, [orgMessages, serviceChecked, dispatchChecked, salesChecked, dcChecked]);

    const removeMessage = useCallback((serviceQueID) => {
        const newOrgMessages = orgMessages.filter(msg => msg.serviceQueID !== serviceQueID);
        setOrgMessages(newOrgMessages);
        
        const filteredMessages = applyFilters(newOrgMessages, serviceChecked, dispatchChecked, salesChecked, dcChecked);
        setMessages(filteredMessages);
    }, [orgMessages, serviceChecked, dispatchChecked, salesChecked, dcChecked, applyFilters]);

    const messageSubmit = () => {
        setMsgBoxOpen(false);
        HttpRequest.sendDriverMessage(accessToken, selectedDriver, companyID, undefined, msgToDriver)
            .then(
                async (body) => {
                    console.log("sendDriverMessage -" + JSON.stringify(body.data));
                },
                function errorCallback(err) {
                    alert("Failed to sendDriverMessage. Error is : " + err);
                }
            );
    };

    useEffect(() => {       
        pollData(); // Initial data fetch
    }, [pollData]);
   
    return (
        <div className="root">
            <DashboardBar 
                tripStats={tripStats} 
                accessToken={accessToken} 
                companyID={companyID}
                serviceChecked={serviceChecked}
                dispatchChecked={dispatchChecked}
                setServiceChecked={setServiceChecked}
                setDispatchChecked={setDispatchChecked}
                salesChecked={salesChecked}
                setSalesChecked={setSalesChecked}
                dcChecked={dcChecked}
                setDcChecked={setDcChecked}
                pollData={pollData}
                autoRefreshEnabled={autoRefreshEnabled}
                setAutoRefreshEnabled={setAutoRefreshEnabled}
            />
            <DashboardDatagrid 
                messages={messages}  
                accessToken={accessToken} 
                pollData={pollData}
                setSelectedDriver={setSelectedDriver}
                removeMessage={removeMessage}
                setMsgBoxOpen={setMsgBoxOpen}
                setOpenWarningDialog={setOpenWarningDialog} 
            />
            <DashboardMessageDialog  
                msgBoxOpen={msgBoxOpen}  
                setMsgBoxOpen={setMsgBoxOpen} 
                setMsgToDriver={setMsgToDriver} 
                messageSubmit={messageSubmit}
            />
            <CustomDialog
                open={openWarningDialog}
                onClose={() => setOpenWarningDialog(false)}
                hasCloseBtn={false}
                dialogMessage="No JobID Attached"
                dialogTitle="Warning"
                dialogAction={() => setOpenWarningDialog(false)}
            />       
            <footer>
                <Copyright textColor='white'/>
            </footer>
        </div>
    );
}