import React, { useState, useEffect } from "react";
import { Button, ButtonGroup } from "@mui/material";
import CalculateIcon from '@mui/icons-material/Calculate';
import AddIcon from "@material-ui/icons/Add";
import MinIcon from "@material-ui/icons/Remove";
import NoticeEmitter from "../../NoticeUtil";
import httpRequest from "../../HttpService";
import Moment from "moment";
import DataGrid, {
  Column,
  Selection,
  Scrolling,
  Paging
} from 'devextreme-react/data-grid';
import "devextreme/dist/css/dx.dark.css";

const TripDriverList = (props) => {
  const containerStyle = {
    marginBottom: '44px'
  };
  
  const [tripDriverList, setTripDriverList] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState();

  useEffect(() => {
    getTripDrivers();
  }, []);

  NoticeEmitter.addListener("refreshJobDetail", (data) => {
    setTimeout(() => getTripDrivers(), 500);
  });

  NoticeEmitter.addListener("refreshRating", (data) => {
    setTimeout(() => getTripDrivers(), 500);
  });

  const getTripDrivers = () => {
    httpRequest.getTripDrivers(props.accessToken, props.jobId).then((body) => {
      if (body.data.length !== 0) {
        const driverList = body.data.map((value, index) => ({
          ...value,
          id: index,
          originalTimestampUTC: new Date(value.datestampUTC),
          datestampUTC: new Date(value.datestampUTC).toLocaleString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            month: 'short',
            day: '2-digit',
            year: 'numeric'
          }).replace(',', '').replace(/^([^,]*),(.*)$/, '$1$2'),
          specialPaid: value.specialPaid?.toFixed(2),
          paidOn: value.paidOn?.toFixed(2),
          commPaid: value.commPaid?.toFixed(2),
          commRate: value.commRate?.toFixed(2),
          fuelPaid: value.fuelPaid?.toFixed(2),
          TotalPaid: value.TotalPaid?.toFixed(2)
        }));
        driverList.sort((a, b) => b.originalTimestampUTC - a.originalTimestampUTC);
        setTripDriverList(driverList);
        setSelectedDriver(driverList[0]);
      }
    });
  };

  const RecalculateAndRefresh = async () => {
    try {
      await httpRequest.doTripReCalcDriverSplit(props.accessToken, props.jobId);
      await new Promise(resolve => setTimeout(resolve, 500));
      NoticeEmitter.emit('refreshJobDetail', 'recalculate');
      await getTripDrivers();
    } catch (error) {
      console.error("Error in RecalculateAndRefresh:", error);
    }
  };

  const renderSharedCount = params => {   
    return (
      <div className="flex items-center justify-center space-x-2">     
        <strong className="mx-2">{params.value}</strong>
        <AddIcon
          className="cursor-pointer"
          onClick={() => {
            const content = {
              jobID: parseInt(props.jobId, 10),
              drNum: params.data.drNum,
              shareCount: params.data.shareCount + 1,
            };
            httpRequest.updateDriverShareCount(props.accessToken, content).then(() => {
              setTimeout(() => getTripDrivers(), 1000);
            });
          }}
        />
        <MinIcon
          className="cursor-pointer"
          onClick={() => {
            if (params.data.shareCount === 0) return;
            const content = {
              jobID: parseInt(props.jobId, 10),
              drNum: params.data.drNum,
              shareCount: params.data.shareCount - 1,
            };
            httpRequest.updateDriverShareCount(props.accessToken, content).then(() => {
              setTimeout(() => getTripDrivers(), 1000);
            });
          }}
        />
      </div>
    );
  };

  const renderDrNum = (params) => {
    const textColor = params.data.shareCount > 0 ? '#13ACB1' : '#808080';
    return (
      <span style={{ color: textColor, fontWeight: 'bold' }}>{params.data.drNum}</span>
    );
  };

  const onRowPrepared = (e) => {
    if (e.data && e.data.shareCount === 0) {
      e.rowElement.style.opacity = '0.7';
    }
  };

  const onSelectionChanged = ({selectedRowsData}) => {
    setSelectedDriver(selectedRowsData);
  };

  return (
    <div className="flex flex-col h-96 w-full" style={containerStyle}>
      <ButtonGroup size="small" variant="outlined" className="mb-4">
        <Button 
          onClick={RecalculateAndRefresh}
          className="text-[#007E82] border-[#007E82]"
        >
          <CalculateIcon className="scale-75 align-middle mr-2"/>
          Recalculate Splits and Refresh
        </Button>
      </ButtonGroup>

      <div className="flex-1 w-full">
        <DataGrid
          dataSource={tripDriverList}
          showBorders={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          keyExpr="id"
          rowAlternationEnabled={true}  
          onSelectionChanged={onSelectionChanged}
          onRowPrepared={onRowPrepared}
        >                    
          <Selection mode="single"/>
          <Scrolling mode="virtual" />
          <Paging enabled={false} />

          <Column dataField="drNum" caption="Driver#" alignment="center" width={120} cellRender={renderDrNum}/>
          <Column dataField="specialPaid" caption="specialPaidOn" alignment="center" width={120}/>                     
          <Column dataField="paidOn" caption="paidOn" width={100} alignment="center" /> 
          <Column dataField="commRate" caption="commRate" alignment="center" width={120}/>
          <Column dataField="commPaid" caption="commPaid" alignment="center" width={120}/>  
          <Column dataField="fuelPaid" caption="fuelPaid" alignment="center" width={120}/>
          <Column dataField="TotalPaid" caption="Total Paid" alignment="center" width={140}/>  
          <Column dataField="shareCount" caption="share Count" alignment="center" width={140} cellRender={renderSharedCount}/>
          <Column 
              dataField="datestampUTC" 
              caption="date/time" 
              alignment="center" 
              width={200}
              calculateSortValue={(rowData) => new Date(rowData.originalTimestampUTC)}
          />          
          </DataGrid>
      </div>
    </div>
  );
};

export default TripDriverList;