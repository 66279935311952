import React, {useState, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import pjson from "../package.json";
import NoticeEmitter from './NoticeUtil.js';

function Copyright(props) {
  const textColor = props.textColor || 'black';
  const [apiver, setApiver] = useState(localStorage.getItem("APIVersion", "1.8.30") !== undefined? localStorage.getItem("APIVersion", "1.8.30"): "1.8.30");

  useEffect(() => {
    // Set max listeners to prevent warning
    NoticeEmitter.setMaxListeners(15);
    
    // Add listener
    const listener = (data) => {
      setApiver(data);
    };
    NoticeEmitter.addListener('getAPIVersion', listener);

    // Cleanup function
    return () => {
      NoticeEmitter.removeListener('getAPIVersion', listener);
    };
  }, []); // Empty dependency array means this runs once on mount

  return (
    <Typography variant="body2" color={textColor === "black" ? "textSecondary": "commn.white"} align="center">
      {'Copyright © '}
      DirectIT
      {' '}
      {new Date().getFullYear()}
      {'.  ver'}
      {pjson.version}
      {'   APIs ver'}
      {apiver}
    </Typography>
  );
}

export default Copyright;