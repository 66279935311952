import React, { useState, useEffect } from 'react';
import { Tabs } from 'devextreme-react/tabs';
import { Tooltip } from '@material-ui/core';

const HotkeyTabs = ({ dataSource, selectedIndex, onItemClick, isLattice }) => {
  const [altPressed, setAltPressed] = useState(false);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Alt') {
        e.preventDefault();
        setAltPressed(true);
      } else if (altPressed && /^[1-6]$/.test(e.key)) {
        e.preventDefault();
        const index = parseInt(e.key) - 1;
        if (dataSource[index] && (!dataSource[index].disabled)) {
          onItemClick({ itemData: dataSource[index] });
        }
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === 'Alt') {
        setAltPressed(false);
      }
    };

    const handleBlur = () => setAltPressed(false);

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('blur', handleBlur);
    };
  }, [altPressed, dataSource, onItemClick]);

  return (
    <Tabs
      dataSource={dataSource.map((tab, index) => ({
        ...tab,
        disabled: !isLattice && (tab.id === 4 || tab.id === 5)
      }))}
      selectedIndex={selectedIndex}
      onItemClick={(e) => {
        if (!e.itemData.disabled) {
          onItemClick(e);
        }
      }}
      itemRender={(item, index) => (
        <Tooltip
          title={item.disabled ? "Only Available on Lattice Trip" : 
                altPressed ? `Alt+${index + 1}` : ""}
          arrow
          placement="top"
        >
          <div 
            className={`tab ${selectedIndex === index ? 'tab-selected' : ''} 
                       ${item.disabled ? 'tab-disabled' : ''}
                       ${altPressed && !item.disabled ? 'tab-highlighted' : ''}`}
          >
            {item.text}
            {altPressed && !item.disabled && (
              <span className="hotkey-indicator">
                {index + 1}
              </span>
            )}
          </div>
        </Tooltip>
      )}
    />
  );
};

export default HotkeyTabs;